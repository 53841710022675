$('.ajax-form').each(function() {
	if ($(this).data('submit-url') === undefined) {
		return;
	}

	$(this).submit(function(submitEvent) {
		submitEvent.preventDefault();
		const $form = $(this);
		const submitUrl = $form.data('submit-url');

		$.ajax(submitUrl, {
			method:   $form.attr('method') || 'POST',
			data:     $form.serialize(),
			complete: function(xhr) {
				// @todo Respond with result step or redirection
				alert('Thank you!');
				console.log(xhr);
			}
		});
	});
});