/*
Name: 			Theme Base
Written by: 	Okler Themes - (http://www.okler.net)
Theme Version:	8.0.0
*/
import './jquery.browser.mobile.min';

// Theme
window.theme = {};

// Theme Common Functions
window.theme.fn = {

	getOptions: function(opts) {

		if (typeof (opts) == 'object') {

			return opts;

		} else if (typeof (opts) == 'string') {

			try {
				return JSON.parse(opts.replace(/'/g, '"').replace(';', ''));
			} catch (e) {
				return {};
			}

		} else {

			return {};

		}

	}

};

// Animate
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__animate';

	var PluginAnimate = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginAnimate.defaults = {
		accX:           0,
		accY:           -80,
		delay:          100,
		duration:       '750ms',
		minWindowWidth: 767
	};

	PluginAnimate.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginAnimate.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			var self = this;

			if ($('body').hasClass('loading-overlay-showing')) {
				$(window).on('loading.overlay.ready', function() {
					self.animate();
				});
			} else {
				self.animate();
			}

			return this;
		},

		animate: function() {
			var self              = this,
			    $el               = this.options.wrapper,
			    delay             = 0,
			    duration          = this.options.duration,
			    elTopDistance     = $el.offset().top,
			    windowTopDistance = $(window).scrollTop();

			$el.addClass('appear-animation animated');

			if (!$('html').hasClass('no-csstransitions') && $(window).width() > self.options.minWindowWidth && elTopDistance >= windowTopDistance) {

				$el.appear(function() {

					$el.one('animation:show', function(ev) {
						delay = ($el.attr('data-appear-animation-delay') ? $el.attr('data-appear-animation-delay') : self.options.delay);
						duration = ($el.attr('data-appear-animation-duration') ? $el.attr('data-appear-animation-duration') : self.options.duration);

						if (duration != '750ms') {
							$el.css('animation-duration', duration);
						}

						$el.css('animation-delay', delay + 'ms');

						$el.addClass($el.attr('data-appear-animation') + ' appear-animation-visible');
					});

					$el.trigger('animation:show');

				}, {
					accX: self.options.accX,
					accY: self.options.accY
				});

			} else {

				$el.addClass('appear-animation-visible');

			}

			return this;
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginAnimate: PluginAnimate
	});

	// jquery plugin
	$.fn.themePluginAnimate = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginAnimate($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Before / After
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__beforeafter';

	var PluginBeforeAfter = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginBeforeAfter.defaults = {};

	PluginBeforeAfter.prototype = {
		initialize: function($el, opts) {
			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginBeforeAfter.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {

			if (!($.isFunction($.fn.twentytwenty))) {
				return this;
			}

			var self = this;

			self.options.wrapper
				.twentytwenty(self.options);

			return this;

		}
	};

	// expose to scope
	$.extend(theme, {
		PluginBeforeAfter: PluginBeforeAfter
	});

	// jquery plugin
	$.fn.themePluginBeforeAfter = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginBeforeAfter($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Carousel
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__carousel';

	var PluginCarousel = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginCarousel.defaults = {
		loop:       true,
		responsive: {
			0:    {
				items: 1
			},
			479:  {
				items: 1
			},
			768:  {
				items: 2
			},
			979:  {
				items: 3
			},
			1199: {
				items: 4
			}
		},
		navText:    []
	};

	PluginCarousel.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginCarousel.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			if (!($.isFunction($.fn.owlCarousel))) {
				return this;
			}

			var self = this,
			    $el  = this.options.wrapper;

			// Add Theme Class
			$el.addClass('owl-theme');

			// Add Loading
			$el.addClass('owl-loading');

			// Force RTL according to HTML dir attribute
			if ($('html').attr('dir') == 'rtl') {
				this.options = $.extend(true, {}, this.options, {
					rtl: true
				});
			}

			if (this.options.items == 1) {
				this.options.responsive = {};
			}

			if (this.options.items > 4) {
				this.options = $.extend(true, {}, this.options, {
					responsive: {
						1199: {
							items: this.options.items
						}
					}
				});
			}

			// Auto Height Fixes
			if (this.options.autoHeight) {
				var itemsHeight = [];

				$el.find('.owl-item').each(function() {
					if ($(this).hasClass('active')) {
						itemsHeight.push($(this).height());
					}
				});

				$(window).afterResize(function() {
					$el.find('.owl-stage-outer').height(Math.max.apply(null, itemsHeight));
				});

				$(window).on('load', function() {
					$el.find('.owl-stage-outer').height(Math.max.apply(null, itemsHeight));
				});
			}

			// Initialize OwlCarousel
			$el.owlCarousel(this.options).addClass('owl-carousel-init');

			// Sync
			if ($el.attr('data-sync')) {
				$el.on('change.owl.carousel', function(event) {
					if (event.namespace && event.property.name === 'position') {
						var target = event.relatedTarget.relative(event.property.value, true);
						$($el.data('sync')).owlCarousel('to', target, 300, true);
					}
				});
			}

			// Carousel Center Active Item
			if ($el.hasClass('carousel-center-active-item')) {
				var itemsActive = $el.find('.owl-item.active'),
				    indexCenter = Math.floor(($el.find('.owl-item.active').length - 1) / 2),
				    itemCenter  = itemsActive.eq(indexCenter);

				itemCenter.addClass('current');

				$el.on('change.owl.carousel', function(event) {
					$el.find('.owl-item').removeClass('current');

					setTimeout(function() {
						var itemsActive = $el.find('.owl-item.active'),
						    indexCenter = Math.floor(($el.find('.owl-item.active').length - 1) / 2),
						    itemCenter  = itemsActive.eq(indexCenter);

						itemCenter.addClass('current');
					}, 100);
				});

				// Refresh
				$el.trigger('refresh.owl.carousel');

			}

			// Remove Loading
			$el.removeClass('owl-loading');

			// Remove Height
			$el.css('height', 'auto');

			return this;
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginCarousel: PluginCarousel
	});

	// jquery plugin
	$.fn.themePluginCarousel = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginCarousel($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Chart Circular
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__chartCircular';

	var PluginChartCircular = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginChartCircular.defaults = {
		accX:        0,
		accY:        -150,
		delay:       1,
		barColor:    '#0088CC',
		trackColor:  '#f2f2f2',
		scaleColor:  false,
		scaleLength: 5,
		lineCap:     'round',
		lineWidth:   13,
		size:        175,
		rotate:      0,
		animate:     ({
			duration: 2500,
			enabled:  true
		})
	};

	PluginChartCircular.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginChartCircular.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			if (!($.isFunction($.fn.appear)) || !($.isFunction($.fn.easyPieChart))) {
				return this;
			}

			var self      = this,
			    $el       = this.options.wrapper,
			    value     = ($el.attr('data-percent') ? $el.attr('data-percent') : 0),
			    percentEl = $el.find('.percent');

			$.extend(true, self.options, {
				onStep: function(from, to, currentValue) {
					percentEl.html(parseInt(currentValue));
				}
			});

			$el.attr('data-percent', 0);

			$el.appear(function() {

				$el.easyPieChart(self.options);

				setTimeout(function() {

					$el.data('easyPieChart').update(value);
					$el.attr('data-percent', value);

				}, self.options.delay);

			}, {
				accX: self.options.accX,
				accY: self.options.accY
			});

			return this;
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginChartCircular: PluginChartCircular
	});

	// jquery plugin
	$.fn.themePluginChartCircular = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginChartCircular($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Countdown
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__countdown';

	var PluginCountdown = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginCountdown.defaults = {
		date:             '2030/06/10 12:00:00',
		textDay:          'DAY',
		textHour:         'HRS',
		textMin:          'MIN',
		textSec:          'SEC',
		uppercase:        true,
		numberClass:      '',
		wrapperClass:     '',
		insertHTMLbefore: '',
		insertHTMLafter:  ''
	};

	PluginCountdown.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginCountdown.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			if (!($.isFunction($.fn.countTo))) {
				return this;
			}

			var self         = this,
			    $el          = this.options.wrapper,
			    numberClass  = (self.options.numberClass) ? ' ' + self.options.numberClass : '',
			    wrapperClass = (self.options.wrapperClass) ? ' ' + self.options.wrapperClass : '';

			if (self.options.uppercase) {
				$el.countdown(self.options.date).on('update.countdown', function(event) {
					var $this = $(this).html(event.strftime(self.options.insertHTMLbefore
						+ '<span class="days' + wrapperClass + '"><span class="' + numberClass + '">%D</span> ' + self.options.textDay + '<div class="d-inline text-uppercase">%!d</div></span> '
						+ '<span class="hours' + wrapperClass + '"><span class="' + numberClass + '">%H</span> ' + self.options.textHour + '</span> '
						+ '<span class="minutes' + wrapperClass + '"><span class="' + numberClass + '">%M</span> ' + self.options.textMin + '</span> '
						+ '<span class="seconds' + wrapperClass + '"><span class="' + numberClass + '">%S</span> ' + self.options.textSec + '</span> '
						+ self.options.insertHTMLafter
					));
				});
			} else {
				$el.countdown(self.options.date).on('update.countdown', function(event) {
					var $this = $(this).html(event.strftime(self.options.insertHTMLbefore
						+ '<span class="days' + wrapperClass + '"><span class="' + numberClass + '">%D</span> ' + self.options.textDay + '%!d</span> '
						+ '<span class="hours' + wrapperClass + '"><span class="' + numberClass + '">%H</span> ' + self.options.textHour + '</span> '
						+ '<span class="minutes' + wrapperClass + '"><span class="' + numberClass + '">%M</span> ' + self.options.textMin + '</span> '
						+ '<span class="seconds' + wrapperClass + '"><span class="' + numberClass + '">%S</span> ' + self.options.textSec + '</span> '
						+ self.options.insertHTMLafter
					));
				});
			}

			return this;
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginCountdown: PluginCountdown
	});

	// jquery plugin
	$.fn.themePluginCountdown = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginCountdown($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Counter
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__counter';

	var PluginCounter = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginCounter.defaults = {
		accX:            0,
		accY:            0,
		speed:           3000,
		refreshInterval: 100,
		decimals:        0,
		onUpdate:        null,
		onComplete:      null
	};

	PluginCounter.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginCounter.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			if (!($.isFunction($.fn.countTo))) {
				return this;
			}

			var self = this,
			    $el  = this.options.wrapper;

			$.extend(self.options, {
				onComplete: function() {
					if ($el.data('append')) {
						$el.html($el.html() + $el.data('append'));
					}

					if ($el.data('prepend')) {
						$el.html($el.data('prepend') + $el.html());
					}
				}
			});

			$el.appear(function() {

				$el.countTo(self.options);

			}, {
				accX: self.options.accX,
				accY: self.options.accY
			});

			return this;
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginCounter: PluginCounter
	});

	// jquery plugin
	$.fn.themePluginCounter = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginCounter($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Float Element
(function(theme, $) {

	'use strict';

	theme = theme || {};

	var instanceName = '__floatElement';

	var PluginFloatElement = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginFloatElement.defaults = {
		startPos:           'top',
		speed:              3,
		horizontal:         false,
		transition:         false,
		transitionDelay:    0,
		transitionDuration: 500
	};

	PluginFloatElement.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginFloatElement.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			var self    = this,
			    $el     = this.options.wrapper,
			    $window = $(window),
			    minus;

			if (self.options.style) {
				$el.attr('style', self.options.style);
			}

			if ($window.width() > 767) {

				// Set Start Position
				if (self.options.startPos == 'none') {
					minus = '';
				} else if (self.options.startPos == 'top') {
					$el.css({
						top: 0
					});
					minus = '';
				} else {
					$el.css({
						bottom: 0
					});
					minus = '-';
				}

				// Set Transition
				if (self.options.transition) {
					$el.css({
						transition: 'ease-out transform ' + self.options.transitionDuration + 'ms ' + self.options.transitionDelay + 'ms'
					});
				}

				// First Load
				self.movement(minus);

				// Scroll
				$window.on('scroll', function() {
					self.movement(minus);
				});

			}

			return this;
		},

		movement: function(minus) {
			var self                 = this,
			    $el                  = this.options.wrapper,
			    $window              = $(window),
			    scrollTop            = $window.scrollTop(),
			    elementOffset        = $el.offset().top,
			    currentElementOffset = (elementOffset - scrollTop);

			var scrollPercent = 100 * currentElementOffset / ($window.height());

			if ($el.visible(true)) {

				if (!self.options.horizontal) {

					$el.css({
						transform: 'translate3d(0, ' + minus + scrollPercent / self.options.speed + '%, 0)'
					});

				} else {

					$el.css({
						transform: 'translate3d(' + minus + scrollPercent / self.options.speed + '%, ' + minus + scrollPercent / self.options.speed + '%, 0)'
					});

				}

			}
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginFloatElement: PluginFloatElement
	});

	// jquery plugin
	$.fn.themePluginFloatElement = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginFloatElement($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Icon
(function(theme, $) {

	'use strict';

	theme = theme || {};

	var instanceName = '__icon';

	var PluginIcon = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginIcon.defaults = {
		color:    '#2388ED',
		animated: false,
		delay:    300,
		onlySVG:  false
	};

	PluginIcon.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginIcon.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build:              function() {
			var self              = this,
			    $el               = this.options.wrapper,
			    color             = self.options.color,
			    elTopDistance     = $el.offset().top,
			    windowTopDistance = $(window).scrollTop(),
			    duration          = (self.options.animated && !self.options.strokeBased) ? 200 : 100;

			// Check origin
			if (window.location.origin === 'file://') {
				$el.css('opacity', 1);
				return;
			}

			// Duration
			if (self.options.duration) {
				duration = self.options.duration;
			}

			// SVG Content
			var SVGContent = $.get({
				url:     $el.attr('src'),
				success: function(data, status, xhr) {
					var iconWrapper = $('<div class="animated-icon">' + xhr.responseText + '</div>'),
					    uniqid      = 'icon_' + Math.floor(Math.random() * 26) + Date.now();

					iconWrapper.find('svg').attr('id', uniqid);

					if ($el.attr('width')) {
						iconWrapper.find('svg')
							.attr('width', $el.attr('width'))
							.attr('height', $el.attr('width'));
					}

					$el.replaceWith(iconWrapper);

					if (self.options.extraClass) {
						iconWrapper.addClass(self.options.extraClass);
					}

					if (self.options.onlySVG) {
						return;
					}

					$el = iconWrapper;

					var icon = new Vivus(uniqid, {
						start: 'manual', type: 'sync', selfDestroy: true, duration: duration, onReady: function(obj) {
							var styleElement = document.createElementNS('http://www.w3.org/2000/svg', 'style'),
							    animateStyle = '';

							// SVG Fill Based
							if (self.options.animated && !self.options.strokeBased || !self.options.animated && color && !self.options.strokeBased) {
								animateStyle = 'stroke-width: 0.1px; fill-opacity: 0; transition: ease fill-opacity 300ms;';

								// Set Style on SVG inside object
								styleElement.textContent = '#' + uniqid + ' path, #' + uniqid + ' line, #' + uniqid + ' rect, #' + uniqid + ' circle, #' + uniqid + ' polyline { fill: ' + color + '; stroke: ' + color + '; ' + animateStyle + (self.options.svgStyle ? self.options.svgStyle : '') + ' } .finished path { fill-opacity: 1; }';
								obj.el.appendChild(styleElement);
							}

							// SVG Stroke Based
							if (self.options.animated && self.options.strokeBased || !self.options.animated && color && self.options.strokeBased) {

								// Set Style on SVG inside object
								styleElement.textContent = '#' + uniqid + ' path, #' + uniqid + ' line, #' + uniqid + ' rect, #' + uniqid + ' circle, #' + uniqid + ' polyline { stroke: ' + color + '; ' + (self.options.svgStyle ? self.options.svgStyle : '') + '}';
								obj.el.appendChild(styleElement);
							}

							$.event.trigger('theme.plugin.icon.svg.ready');
						}
					});

					// Isn't animated
					if (!self.options.animated) {
						setTimeout(function() {
							icon.finish();
						}, 10);
						$el.css({opacity: 1});
					}

					// Animated
					if (self.options.animated && $(window).width() > 767) {
						// First Load
						if ($el.visible(true)) {
							self.startIconAnimation(icon, $el);
						} else if (elTopDistance < windowTopDistance) {
							self.startIconAnimation(icon, $el);
						}

						// On Scroll
						$(window).on('scroll', function() {
							if ($el.visible(true)) {
								self.startIconAnimation(icon, $el);
							}
						});

					} else {

						$el.css({opacity: 1});
						icon.finish();

						$(window).on('theme.plugin.icon.svg.ready', function() {
							setTimeout(function() {
								icon.el.setAttribute('class', 'finished');
								icon.finish();
							}, 300);
						});

					}

				}
			});

			return this;
		},
		startIconAnimation: function(icon, $el) {
			var self = this;

			// Animate for better performance
			$({to: 0}).animate({to: 1}, ((self.options.strokeBased) ? self.options.delay : self.options.delay + 300), function() {
				$el.css({opacity: 1});
			});

			$({to: 0}).animate({to: 1}, self.options.delay, function() {
				icon.play(1);

				setTimeout(function() {
					icon.el.setAttribute('class', 'finished');
				}, icon.duration * 5);
			});
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginIcon: PluginIcon
	});

	// jquery plugin
	$.fn.themePluginIcon = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginIcon($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Lazy Load
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__lazyload';

	var PluginLazyLoad = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginLazyLoad.defaults = {
		effect:       'show',
		appearEffect: '',
		imgFluid:     true,
		appear:       function(elements_left, settings) {

		},
		load:         function(elements_left, settings) {
			$(this).addClass($.trim('lazy-load-loaded ' + settings.appearEffect)).css({
				'animation-duration': '1s'
			});
		}
	};

	PluginLazyLoad.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build()
				.events();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginLazyLoad.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			if (!($.isFunction($.fn.lazyload))) {
				return this;
			}

			var self = this;

			self.options.wrapper.lazyload(this.options);

			return this;
		},

		events: function() {
			var self = this;

			if (self.options.imgFluid && self.options.wrapper.is('img')) {
				self.options.wrapper.on('appear', function() {
					setTimeout(function() {
						self.options.wrapper.addClass('img-fluid');
					}, 500);
				});
			}

			return this;
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginLazyLoad: PluginLazyLoad
	});

	// jquery plugin
	$.fn.themePluginLazyLoad = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginLazyLoad($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Lightbox
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__lightbox';

	var PluginLightbox = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginLightbox.defaults = {
		tClose:    'Close (Esc)', // Alt text on close button
		tLoading:  'Loading...', // Text that is displayed during loading. Can contain %curr% and %total% keys
		gallery:   {
			tPrev:    'Previous (Left arrow key)', // Alt text on left arrow
			tNext:    'Next (Right arrow key)', // Alt text on right arrow
			tCounter: '%curr% of %total%' // Markup for "1 of 7" counter
		},
		image:     {
			tError: '<a href="%url%">The image</a> could not be loaded.' // Error message when image could not be loaded
		},
		ajax:      {
			tError: '<a href="%url%">The content</a> could not be loaded.' // Error message when ajax request failed
		},
		callbacks: {
			open:  function() {
				$('html').addClass('lightbox-opened');
			},
			close: function() {
				$('html').removeClass('lightbox-opened');
			}
		}
	};

	PluginLightbox.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginLightbox.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			if (!($.isFunction($.fn.magnificPopup))) {
				return this;
			}

			this.options.wrapper.magnificPopup(this.options);

			return this;
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginLightbox: PluginLightbox
	});

	// jquery plugin
	$.fn.themePluginLightbox = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginLightbox($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Loading Overlay
(function(theme, $) {

	'use strict';

	theme = theme || {};

	var loadingOverlayTemplate = [
		'<div class="loading-overlay">',
		'<div class="bounce-loader"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>',
		'</div>'
	].join('');

	var LoadingOverlay = function($wrapper, options) {
		return this.initialize($wrapper, options);
	};

	LoadingOverlay.prototype = {

		options: {
			css:       {},
			hideDelay: 500
		},

		initialize: function($wrapper, options) {
			this.$wrapper = $wrapper;

			this
				.setVars()
				.setOptions(options)
				.build()
				.events();

			this.$wrapper.data('loadingOverlay', this);
		},

		setVars: function() {
			this.$overlay = this.$wrapper.find('.loading-overlay');

			return this;
		},

		setOptions: function(options) {
			if (!this.$overlay.get(0)) {
				this.matchProperties();
			}
			this.options = $.extend(true, {}, this.options, options, theme.fn.getOptions(this.$wrapper.data('plugin-options')));

			this.loaderClass = this.getLoaderClass(this.options.css.backgroundColor);

			return this;
		},

		build: function() {
			if (!this.$overlay.closest(document.documentElement).get(0)) {
				if (!this.$cachedOverlay) {
					this.$overlay = $(loadingOverlayTemplate).clone();

					if (this.options.css) {
						this.$overlay.css(this.options.css);
						this.$overlay.find('.loader').addClass(this.loaderClass);
					}
				} else {
					this.$overlay = this.$cachedOverlay.clone();
				}

				this.$wrapper.append(this.$overlay);
			}

			if (!this.$cachedOverlay) {
				this.$cachedOverlay = this.$overlay.clone();
			}

			return this;
		},

		events: function() {
			var _self = this;

			if (this.options.startShowing) {
				_self.show();
			}

			if (this.$wrapper.is('body') || this.options.hideOnWindowLoad) {
				$(window).on('load error', function() {
					_self.hide();
				});
			}

			if (this.options.listenOn) {
				$(this.options.listenOn)
					.on('loading-overlay:show beforeSend.ic', function(e) {
						e.stopPropagation();
						_self.show();
					})
					.on('loading-overlay:hide complete.ic', function(e) {
						e.stopPropagation();
						_self.hide();
					});
			}

			this.$wrapper
				.on('loading-overlay:show beforeSend.ic', function(e) {
					if (e.target === _self.$wrapper.get(0)) {
						e.stopPropagation();
						_self.show();
						return true;
					}
					return false;
				})
				.on('loading-overlay:hide complete.ic', function(e) {
					if (e.target === _self.$wrapper.get(0)) {
						e.stopPropagation();
						_self.hide();
						return true;
					}
					return false;
				});

			return this;
		},

		show: function() {
			this.build();

			this.position = this.$wrapper.css('position').toLowerCase();
			if (this.position != 'relative' || this.position != 'absolute' || this.position != 'fixed') {
				this.$wrapper.css({
					position: 'relative'
				});
			}
			this.$wrapper.addClass('loading-overlay-showing');
		},

		hide: function() {
			var _self = this;

			setTimeout(function() {
				_self.$wrapper.removeClass('loading-overlay-showing');

				if (this.position != 'relative' || this.position != 'absolute' || this.position != 'fixed') {
					_self.$wrapper.css({position: ''});
				}

				$(window).trigger('loading.overlay.ready');
			}, _self.options.hideDelay);
		},

		matchProperties: function() {
			var i,
			    l,
			    properties;

			properties = [
				'backgroundColor',
				'borderRadius'
			];

			l = properties.length;

			for (i = 0; i < l; i++) {
				var obj = {};
				obj[properties[i]] = this.$wrapper.css(properties[i]);

				$.extend(this.options.css, obj);
			}
		},

		getLoaderClass: function(backgroundColor) {
			if (!backgroundColor || backgroundColor === 'transparent' || backgroundColor === 'inherit') {
				return 'black';
			}

			var hexColor,
			    r,
			    g,
			    b,
			    yiq;

			var colorToHex = function(color) {
				var hex,
				    rgb;

				if (color.indexOf('#') > -1) {
					hex = color.replace('#', '');
				} else {
					rgb = color.match(/\d+/g);
					hex = ('0' + parseInt(rgb[0], 10).toString(16)).slice(-2) + ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) + ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2);
				}

				if (hex.length === 3) {
					hex = hex + hex;
				}

				return hex;
			};

			hexColor = colorToHex(backgroundColor);

			r = parseInt(hexColor.substr(0, 2), 16);
			g = parseInt(hexColor.substr(2, 2), 16);
			b = parseInt(hexColor.substr(4, 2), 16);
			yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

			return (yiq >= 128) ? 'black' : 'white';
		}

	};

	// expose to scope
	$.extend(theme, {
		LoadingOverlay: LoadingOverlay
	});

	// expose as a jquery plugin
	$.fn.loadingOverlay = function(opts) {
		return this.each(function() {
			var $this = $(this);

			var loadingOverlay = $this.data('loadingOverlay');
			if (loadingOverlay) {
				return loadingOverlay;
			} else {
				var options = opts || $this.data('loading-overlay-options') || {};
				return new LoadingOverlay($this, options);
			}
		});
	};

	// auto init
	$('[data-loading-overlay]').loadingOverlay();

}).apply(this, [window.theme, jQuery]);

// Masonry
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__masonry';

	var PluginMasonry = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginMasonry.defaults = {};

	PluginMasonry.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginMasonry.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			if (!($.isFunction($.fn.isotope))) {
				return this;
			}

			var self    = this,
			    $window = $(window);

			self.$loader = false;

			if (self.options.wrapper.parents('.masonry-loader').get(0)) {
				self.$loader = self.options.wrapper.parents('.masonry-loader');
				self.createLoader();
			}

			self.options.wrapper.one('layoutComplete', function(event, laidOutItems) {
				self.removeLoader();
			});

			self.options.wrapper.waitForImages(function() {
				self.options.wrapper.isotope(self.options);
			});

			// IE10/11 fix
			if ($('html').hasClass('ie10') || $('html').hasClass('ie11')) {
				var padding = parseInt(self.options.wrapper.children().css('padding-left')) + parseInt(self.options.wrapper.children().css('padding-right'));
			}

			$(window).on('resize', function() {
				setTimeout(function() {
					self.options.wrapper.isotope('layout');
				}, 300);
			});

			setTimeout(function() {
				self.removeLoader();
			}, 3000);

			return this;
		},

		createLoader: function() {
			var self = this;

			var loaderTemplate = [
				'<div class="bounce-loader">',
				'<div class="bounce1"></div>',
				'<div class="bounce2"></div>',
				'<div class="bounce3"></div>',
				'</div>'
			].join('');

			self.$loader.append(loaderTemplate);

			return this;
		},

		removeLoader: function() {

			var self = this;

			if (self.$loader) {

				self.$loader.removeClass('masonry-loader-showing');

				setTimeout(function() {
					self.$loader.addClass('masonry-loader-loaded');
				}, 300);

			}

		}
	};

	// expose to scope
	$.extend(theme, {
		PluginMasonry: PluginMasonry
	});

	// jquery plugin
	$.fn.themePluginMasonry = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginMasonry($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Match Height
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__matchHeight';

	var PluginMatchHeight = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginMatchHeight.defaults = {
		byRow:    true,
		property: 'height',
		target:   null,
		remove:   false
	};

	PluginMatchHeight.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginMatchHeight.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			if (!($.isFunction($.fn.matchHeight))) {
				return this;
			}

			var self = this;

			self.options.wrapper.matchHeight(self.options);

			return this;
		}

	};

	// expose to scope
	$.extend(theme, {
		PluginMatchHeight: PluginMatchHeight
	});

	// jquery plugin
	$.fn.themePluginMatchHeight = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginMatchHeight($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Parallax
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__parallax';

	var PluginParallax = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginParallax.defaults = {
		speed:              1.5,
		horizontalPosition: '50%',
		offset:             0,
		parallaxHeight:     '180%',
		enableOnMobile:     false
	};

	PluginParallax.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginParallax.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			var self    = this,
			    $window = $(window),
			    offset,
			    yPos,
			    bgpos,
			    background;

			// Create Parallax Element
			background = $('<div class="parallax-background"></div>');

			// Set Style for Parallax Element
			background.css({
				'background-image': 'url(' + self.options.wrapper.data('image-src') + ')',
				'background-size':  'cover',
				'position':         'absolute',
				'top':              0,
				'left':             0,
				'width':            '100%',
				'height':           self.options.parallaxHeight
			});

			// Add Parallax Element on DOM
			self.options.wrapper.prepend(background);

			// Set Overlfow Hidden and Position Relative to Parallax Wrapper
			self.options.wrapper.css({
				'position': 'relative',
				'overflow': 'hidden'
			});

			// Parallax Effect on Scroll & Resize
			var parallaxEffectOnScrolResize = function() {
				$window.on('scroll resize', function() {
					offset = self.options.wrapper.offset();
					yPos = -($window.scrollTop() - (offset.top - 100)) / ((self.options.speed + 2));
					let plxPos = (yPos < 0) ? Math.abs(yPos) : -Math.abs(yPos);
					background.css({
						'transform':             'translate3d(0, ' + ((plxPos - 50) + (self.options.offset)) + 'px, 0)',
						'background-position-x': self.options.horizontalPosition
					});
				});

				$window.trigger('scroll');
			};

			if (!$.browser.mobile) {
				parallaxEffectOnScrolResize();
			} else {
				if (self.options.enableOnMobile === true) {
					parallaxEffectOnScrolResize();
				} else {
					self.options.wrapper.addClass('parallax-disabled');
				}
			}

			return this;
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginParallax: PluginParallax
	});

	// jquery plugin
	$.fn.themePluginParallax = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginParallax($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Progress Bar
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__progressBar';

	var PluginProgressBar = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginProgressBar.defaults = {
		accX:  0,
		accY:  -50,
		delay: 1
	};

	PluginProgressBar.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginProgressBar.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			if (!($.isFunction($.fn.appear))) {
				return this;
			}

			var self  = this,
			    $el   = this.options.wrapper,
			    delay = 1;

			$el.appear(function() {

				delay = ($el.attr('data-appear-animation-delay') ? $el.attr('data-appear-animation-delay') : self.options.delay);

				$el.addClass($el.attr('data-appear-animation'));

				setTimeout(function() {

					$el.animate({
						width: $el.attr('data-appear-progress-animation')
					}, 1500, 'easeOutQuad', function() {
						$el.find('.progress-bar-tooltip').animate({
							opacity: 1
						}, 500, 'easeOutQuad');
					});

				}, delay);

			}, {
				accX: self.options.accX,
				accY: self.options.accY
			});

			return this;
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginProgressBar: PluginProgressBar
	});

	// jquery plugin
	$.fn.themePluginProgressBar = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginProgressBar($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Revolution Slider
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__revolution';

	var PluginRevolutionSlider = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginRevolutionSlider.defaults = {
		sliderType:         'standard',
		sliderLayout:       'fullwidth',
		delay:              9000,
		gridwidth:          1170,
		gridheight:         500,
		spinner:            'spinner3',
		disableProgressBar: 'off',
		parallax:           {
			type:       'off',
			bgparallax: 'off'
		},
		navigation:         {
			keyboardNavigation:    'off',
			keyboard_direction:    'horizontal',
			mouseScrollNavigation: 'off',
			onHoverStop:           'off',
			touch:                 {
				touchenabled:        'on',
				swipe_threshold:     75,
				swipe_min_touches:   1,
				swipe_direction:     'horizontal',
				drag_block_vertical: false
			},
			arrows:                {
				enable:            true,
				hide_onmobile:     false,
				hide_under:        0,
				hide_onleave:      true,
				hide_delay:        200,
				hide_delay_mobile: 1200,
				left:              {
					h_align:  'left',
					v_align:  'center',
					h_offset: 30,
					v_offset: 0
				},
				right:             {
					h_align:  'right',
					v_align:  'center',
					h_offset: 30,
					v_offset: 0
				}
			}
		},

		/* ADDONS */
		addOnTypewriter:  {
			enable: false
		},
		addOnWhiteboard:  {
			enable: false,

		},
		whiteboard:       {
			movehand:  {
				src:       '../vendor/rs-plugin/revolution-addons/whiteboard/assets/images/hand_point_right.png',
				width:     400,
				height:    1000,
				handtype:  'right',
				transform: {
					transformX: 50,
					transformY: 50
				},
				jittering: {
					distance:            '80',
					distance_horizontal: '100',
					repeat:              '5',
					offset:              '10',
					offset_horizontal:   '0'
				},
				rotation:  {
					angle:  '10',
					repeat: '3'
				}
			},
			writehand: {
				src:       '../vendor/rs-plugin/revolution-addons/whiteboard/assets/images/write_right_angle.png',
				width:     572,
				height:    691,
				handtype:  'right',
				transform: {
					transformX: 50,
					transformY: 50
				},
				jittering: {
					distance:            '80',
					distance_horizontal: '100',
					repeat:              '5',
					offset:              '10',
					offset_horizontal:   '0'
				},
				rotation:  {
					angle:  '10',
					repeat: '3'
				}
			}
		},
		addOnParticles:   {
			enable: false
		},
		particles:        {
			startSlide:    'first',
			endSlide:      'last',
			zIndex:        '1',
			particles:     {
				number:      {value: 80}, color: {value: '#ffffff'},
				shape:       {
					type:  'circle', stroke: {width: 0, color: '#ffffff', opacity: 1},
					image: {src: ''}
				},
				opacity:     {
					value:  0.5,
					random: true,
					min:    0.25,
					anim:   {enable: false, speed: 3, opacity_min: 0, sync: false}
				},
				size:        {
					value:  2,
					random: false,
					min:    30,
					anim:   {enable: false, speed: 40, size_min: 1, sync: false}
				},
				line_linked: {enable: true, distance: 150, color: '#ffffff', opacity: 0.4, width: 1},
				move:        {
					enable:    true,
					speed:     6,
					direction: 'none',
					random:    true,
					min_speed: 6,
					straight:  false,
					out_mode:  'out'
				}
			},
			interactivity: {
				events: {onhover: {enable: false, mode: 'repulse'}, onclick: {enable: false, mode: 'repulse'}},
				modes:  {
					grab:    {distance: 400, line_linked: {opacity: 0.5}},
					bubble:  {distance: 400, size: 40, opacity: 0.4},
					repulse: {distance: 200}
				}
			}
		},
		addOnCountdown:   {
			enable:       false,
			targetdate:   new Date().getTime() + 864000000, // http://www.freeformatter.com/epoch-timestamp-to-date-converter.html
			slidechanges: [{days: 0, hours: 0, minutes: 0, seconds: 0, slide: 2}]
		},
		addOnSlicey:      {
			enable: false
		},
		addOnFilmstrip:   {
			enable: false
		},
		addOnBeforeAfter: {
			enable:  false,
			options: {
				cursor:        'move',
				carousel:      false,
				arrowStyles:   {
					leftIcon:     'fa-icon-caret-left',
					rightIcon:    'fa-icon-caret-right',
					topIcon:      'fa-icon-caret-up',
					bottomIcon:   'fa-icon-caret-down',
					size:         '35',
					color:        '#ffffff',
					spacing:      '10',
					bgColor:      'transparent',
					padding:      '0',
					borderRadius: '0'
				},
				dividerStyles: {
					width: '1',
					color: 'rgba(255, 255, 255, 0.5)'
				}
			}
		},
		addOnPanorama:    {
			enable: false
		},
		addOnRevealer:    {
			enable: false,
		},
		revealer:         {
			direction:        'open_horizontal',
			color:            '#ffffff',
			duration:         '1500',
			delay:            '0',
			easing:           'Power2.easeInOut',
			overlay_enabled:  true,
			overlay_color:    '#000000',
			overlay_duration: '1500',
			overlay_delay:    '0',
			overlay_easing:   'Power2.easeInOut',
			spinner:          '1',
			spinnerColor:     '#006dd2',
			spinnerHtml:      '<div class=\'rsaddon-revealer-spinner rsaddon-revealer-spinner-1\'><div class=\'rsaddon-revealer-1\'><span style=\'background: {{color}}\'><\/span><span style=\'background: {{color}}\'><\/span><span style=\'background: {{color}}\'><\/span><span style=\'background: {{color}}\'><\/span><span style=\'background: {{color}}\'><\/span><span style=\'background: {{color}}\'><\/span><span style=\'background: {{color}}\'><\/span><span style=\'background: {{color}}\'><\/span><span style=\'background: {{color}}\'><\/span><span style=\'background: {{color}}\'><\/span><\/div><\/div \/>'
		},
		addOnDuotone:     {
			enable: false
		},
		addOnBubblemorph: {
			enable: false
		},
		addOnDistortion:  {
			enable: false
		}

	};

	PluginRevolutionSlider.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build()
				.events();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginRevolutionSlider.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			if (!($.isFunction($.fn.revolution))) {
				return this;
			}

			// Single Slider Class
			if (this.options.wrapper.find('> ul > li').length == 1) {
				this.options.wrapper.addClass('slider-single-slide');

				// Remove Bullets
				// this.options.navigation.bullets.enable = false;
				$.extend(this.options.navigation, {
					bullets: {
						enable: false
					}
				});

			}

			// Full Screen Class
			if (this.options.sliderLayout == 'fullscreen') {
				this.options.wrapper.closest('.slider-container').addClass('fullscreen-slider');
			}

			// Initialize Revolution Slider
			this.options.wrapper.revolution(this.options);

			// Addon Init - Typewriter
			if (this.options.addOnTypewriter.enable) {
				RsTypewriterAddOn($, this.options.wrapper);
			}

			// Addon Init - Whiteboard
			if (this.options.addOnWhiteboard.enable) {
				this.options.wrapper.rsWhiteBoard();
			}

			// Addon Init - Particles
			if (this.options.addOnParticles.enable) {
				RsParticlesAddOn(this.options.wrapper);
			}

			// Addon Init - Countdown
			if (this.options.addOnCountdown.enable) {
				tp_countdown(this.options.wrapper, this.options.addOnCountdown.targetdate, this.options.addOnCountdown.slidechanges);
			}

			// Addon Init - Slicey
			if (this.options.addOnSlicey.enable) {
				this.options.wrapper.revSliderSlicey();
			}

			// Addon Init - Filmstrip
			if (this.options.addOnFilmstrip.enable) {
				RsFilmstripAddOn($, this.options.wrapper, '../vendor/rs-plugin/revolution-addons/filmstrip/', false);
			}

			// Addon Init - Before After
			if (this.options.addOnBeforeAfter.enable) {
				RevSliderBeforeAfter($, this.options.wrapper, this.options.addOnBeforeAfter.options);
			}

			// Addon Init - Panorama
			if (this.options.addOnPanorama.enable) {
				RsAddonPanorama($, this.options.wrapper);
			}

			// Addon Init - Revealer
			if (this.options.addOnRevealer.enable) {
				RsRevealerAddOn($, this.options.wrapper, this.options.revealer.spinnerHtml);
			}

			// Addon Init - Duotone
			if (this.options.addOnDuotone.enable) {
				RsAddonDuotone($, this.options.wrapper, true, 'cubic-bezier(0.645, 0.045, 0.355, 1.000)', '1000');
			}

			// Addon Init - Bubblemorph
			if (this.options.addOnBubblemorph.enable) {
				BubbleMorphAddOn($, this.options.wrapper, false);
			}

			// Addon Init - Distortion
			if (this.options.addOnDistortion.enable) {
				RsLiquideffectAddOn($, this.options.wrapper);
			}

			return this;
		},

		events: function() {

			return this;
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginRevolutionSlider: PluginRevolutionSlider
	});

	// jquery plugin
	$.fn.themePluginRevolutionSlider = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginRevolutionSlider($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Scrollable
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__scrollable';

	var PluginScrollable = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginScrollable.updateModals = function() {
		PluginScrollable.updateBootstrapModal();
	};

	PluginScrollable.updateBootstrapModal = function() {
		var updateBoostrapModal;

		updateBoostrapModal = typeof $.fn.modal !== 'undefined';
		updateBoostrapModal = updateBoostrapModal && typeof $.fn.modal.Constructor !== 'undefined';
		updateBoostrapModal = updateBoostrapModal && typeof $.fn.modal.Constructor.prototype !== 'undefined';
		updateBoostrapModal = updateBoostrapModal && typeof $.fn.modal.Constructor.prototype.enforceFocus !== 'undefined';

		if (!updateBoostrapModal) {
			return false;
		}

		var originalFocus = $.fn.modal.Constructor.prototype.enforceFocus;
		$.fn.modal.Constructor.prototype.enforceFocus = function() {
			originalFocus.apply(this);

			var $scrollable = this.$element.find('.scrollable');
			if ($scrollable) {
				if ($.isFunction($.fn['themePluginScrollable'])) {
					$scrollable.themePluginScrollable();
				}

				if ($.isFunction($.fn['nanoScroller'])) {
					$scrollable.nanoScroller();
				}
			}
		};
	};

	PluginScrollable.defaults = {
		contentClass:         'scrollable-content',
		paneClass:            'scrollable-pane',
		sliderClass:          'scrollable-slider',
		alwaysVisible:        true,
		preventPageScrolling: true
	};

	PluginScrollable.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginScrollable.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			this.options.wrapper.nanoScroller(this.options);

			return this;
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginScrollable: PluginScrollable
	});

	// jquery plugin
	$.fn.themePluginScrollable = function(opts) {
		return this.each(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginScrollable($this, opts);
			}

		});
	};

	$(function() {
		PluginScrollable.updateModals();
	});

}).apply(this, [window.theme, jQuery]);

// Section Scroll
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__sectionScroll';

	var PluginSectionScroll = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginSectionScroll.defaults = {
		targetClass:      '.section',
		dotsNav:          true,
		changeHeaderLogo: true,
		headerLogoDark:   '/dist/images/logo.svg',
		headerLogoLight:  '/dist/images/logo-inv.svg'
	};

	PluginSectionScroll.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build()
				.events();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginSectionScroll.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			var self = this,
			    $el  = this.options.wrapper;

			// Check type of header and change the target for header (by change header color purpose)
			if ($('html').hasClass('side-header-overlay-full-screen')) {
				self.$header = $('.sticky-wrapper');
			} else {
				self.$header = $('#header');
			}

			// Turn the section full height or not depeding on the content size
			self.updateSectionsHeight();

			// Wrap all sections in a section wrapper
			$(this.options.targetClass).wrap('<div class="section-wrapper"></div>');

			// Set the section wrapper height
			$('.section-wrapper').each(function() {
				$(this).height($(this).find('.section-scroll').outerHeight());
			});

			// Add active class to the first section on page load
			$('.section-wrapper').first().addClass('active');

			var flag           = false,
			    scrollableFlag = false,
			    touchDirection = '',
			    touchstartY    = 0,
			    touchendY      = 0;

			$(window).on('touchstart', function(event) {
				touchstartY = event.changedTouches[0].screenY;
			});

			var wheelEvent = 'onwheel' in document ? 'wheel' : document.onmousewheel !== undefined ? 'mousewheel' : 'DOMMouseScroll';
			if ($(window).width() < 992 && $('html').hasClass('touch')) {
				wheelEvent = 'onwheel' in document ? 'wheel touchend' : document.onmousewheel !== undefined ? 'mousewheel touchend' : 'DOMMouseScroll touchend';
			}

			$(window).on(wheelEvent, function(e) {
				if ($(window).width() < 992 && $('html').hasClass('touch')) {
					if ($(e.target).closest('.section-scroll-dots-navigation').get(0) || $(e.target).closest('.header-body').get(0) || $(e.target).closest('.owl-carousel').get(0)) {
						return;
					}
				}

				// Side Header Overlay Full Screen
				if ($('html.side-header-overlay-full-screen.side-header-hide').get(0)) {
					return;
				}

				var wheelDirection = e.originalEvent.wheelDelta == undefined ? e.originalEvent.deltaY > 0 : e.originalEvent.wheelDelta < 0;
				if ($(window).width() < 992 && $('html').hasClass('touch')) {
					touchendY = event.changedTouches[0].screenY;

					if (touchendY <= touchstartY) {
						touchDirection = 'up';
					}

					if (touchendY >= touchstartY) {
						touchDirection = 'down';
					}

					if (touchendY == touchstartY) {
						return;
					}
				}

				var $currentSection = $('.section-wrapper').eq(self.getCurrentIndex()).find('.section-scroll'),
				    $nextSection    = self.getNextSection(wheelDirection, touchDirection),
				    nextSectionOffsetTop;

				// If is the last section, then change the offsetTop value
				if (self.getCurrentIndex() == $('.section-wrapper').length - 1) {
					nextSectionOffsetTop = $(document).height();
				} else {
					nextSectionOffsetTop = $nextSection.offset().top;
				}

				if ($(window).width() < 992 && $('html').hasClass('touch')) {
					setTimeout(function() {
						if ($('.section-wrapper').eq(self.getCurrentIndex()).find('.section-scroll').hasClass('section-scroll-scrollable')) {
							$('html').removeClass('overflow-hidden');
						} else {
							$('html').addClass('overflow-hidden');
						}
					}, 1200);
				}

				// For non full height sections
				if ($currentSection.hasClass('section-scroll-scrollable')) {
					if (!flag && !scrollableFlag) {

						// Scroll Direction
						if (wheelDirection || touchDirection == 'up') {
							if (($(window).scrollTop() + $(window).height()) >= nextSectionOffsetTop) {
								flag = true;
								setTimeout(function() {
									$(window).trigger('section.scroll.change.header.color');

									setTimeout(function() {
										flag = false;
									}, 500);
								}, 1000);

								if (self.getCurrentIndex() == ($('.section-wrapper').length - 1)) {
									return false;
								}

								// Move to the next section
								self.moveTo($currentSection.offset().top + $currentSection.outerHeight());

								// Change Section Active Class
								self.changeSectionActiveState($nextSection);

								self.$header.css({
									opacity:    0,
									transition: 'ease opacity 500ms'
								});
							}

							if (!$('html').hasClass('touch')) {
								for (var i = 1; i < 100; i++) {
									$('body, html').scrollTop($(window).scrollTop() + 1);

									if (($(window).scrollTop() + $(window).height()) >= nextSectionOffsetTop) {
										scrollableFlag = true;
										setTimeout(function() {
											$(window).trigger('section.scroll.change.header.color');
											scrollableFlag = false;
										}, 500);
										break;
									}
								}
							}
						} else {
							if ($(window).scrollTop() <= $currentSection.offset().top) {
								flag = true;
								setTimeout(function() {
									$(window).trigger('section.scroll.change.header.color');

									setTimeout(function() {
										flag = false;
									}, 500);
								}, 1000);

								if (self.getCurrentIndex() == 0) {
									return false;
								}

								// Move to the next section
								self.moveTo($currentSection.offset().top - $(window).height());

								// Change Section Active Class
								self.changeSectionActiveState($nextSection);

								self.$header.css({
									opacity:    0,
									transition: 'ease opacity 500ms'
								});
							}

							if (!$('html').hasClass('touch')) {
								for (var i = 1; i < 100; i++) {
									$('body, html').scrollTop($(window).scrollTop() - 1);

									if ($(window).scrollTop() <= $currentSection.offset().top) {
										scrollableFlag = true;
										setTimeout(function() {
											$(window).trigger('section.scroll.change.header.color');
											scrollableFlag = false;
										}, 500);
										break;
									}
								}
							}
						}

						// Change Dots Active Class
						self.changeDotsActiveState();

						return;

					}
				}

				// For full height sections
				if (!flag && !scrollableFlag) {
					if (wheelDirection || touchDirection == 'up') {
						if (self.getCurrentIndex() == ($('.section-wrapper').length - 1)) {
							return false;
						}

						// Change Section Active Class
						self.changeSectionActiveState($nextSection);

						setTimeout(function() {
							// Move to the next section
							self.moveTo($nextSection.offset().top);

						}, 150);
					} else {
						if (self.getCurrentIndex() == 0) {
							return false;
						}

						// Change Section Active Class
						self.changeSectionActiveState($nextSection);

						if ($nextSection.height() > $(window).height()) {
							// Move to the next section
							self.moveTo($currentSection.offset().top - $(window).height());
						} else {
							setTimeout(function() {
								// Move to the next section
								self.moveTo($nextSection.offset().top);

							}, 150);
						}
					}

					// Change Dots Active Class
					self.changeDotsActiveState();

					self.$header.css({
						opacity:    0,
						transition: 'ease opacity 500ms'
					});

					// Style next section
					$nextSection.css({
						position:  'relative',
						opacity:   1,
						'z-index': 1,
						transform: 'translate3d(0,0,0) scale(1)'
					});

					// Style previous section
					$currentSection.css({
						position:   'fixed',
						width:      '100%',
						top:        0,
						left:       0,
						opacity:    0,
						'z-index':  0,
						transform:  'translate3d(0,0,-10px) scale(0.7)',
						transition: 'ease transform 600ms, ease opacity 600ms',
					});

					setTimeout(function() {
						$currentSection.css({
							position:  'relative',
							opacity:   1,
							transform: 'translate3d(0,0,-10px) scale(1)'
						});

						$(window).trigger('section.scroll.change.header.color');

						setTimeout(function() {
							flag = false;
						}, 500);
					}, 1000);

					flag = true;

				}

				return;
			});

			// Dots Navigation
			if (this.options.dotsNav) {
				self.dotsNavigation();
			}

			// First Load
			setTimeout(function() {
				if ($(window.location.hash).get(0)) {
					self.moveTo($(window.location.hash).parent().offset().top);

					self.changeSectionActiveState($(window.location.hash));

					// Change Dots Active Class
					self.changeDotsActiveState();

					self.updateHash(true);
				} else {
					var hash  = window.location.hash,
					    index = hash.replace('#', '');

					if (!hash) {
						index = 1;
					}

					self.moveTo($('.section-wrapper').eq(index - 1).offset().top);

					self.changeSectionActiveState($('.section-wrapper').eq(index - 1).find('.section-scroll'));

					// Change Dots Active Class
					self.changeDotsActiveState();

					self.updateHash(true);
				}

				$(window).trigger('section.scroll.ready');
			}, 500);

			return this;
		},

		updateSectionsHeight: function() {
			var self = this;

			$('.section-scroll').css({height: ''});

			$('.section-scroll').each(function() {
				if ($(this).outerHeight() < ($(window).height() + 3)) {
					$(this).css({height: '100vh'});
				} else {
					$(this).addClass('section-scroll-scrollable');
				}
			});

			// Set the section wrapper height
			$('.section-wrapper').each(function() {
				$(this).height($(this).find('.section-scroll').outerHeight());
			});

			return this;
		},

		updateHash: function(first_load) {
			var self = this;

			if (!window.location.hash) {
				window.location.hash = 1;
			} else {
				if (!first_load) {
					var $section   = $('.section-wrapper').eq(self.getCurrentIndex()).find('.section-scroll'),
					    section_id = $section.attr('id') ? $section.attr('id') : $section.parent().index() + 1;

					window.location.hash = section_id;
				}
			}

			return this;
		},

		getCurrentIndex: function() {
			var self         = this,
			    currentIndex = 0;

			currentIndex = $('.section-wrapper.active').index();

			return currentIndex;
		},

		moveTo: function($scrollTopValue, first_load) {
			var self = this;

			$('body, html').animate({
				scrollTop: $scrollTopValue
			}, 1000, 'easeOutQuint');

			setTimeout(function() {
				self.updateHash();
			}, 500);

			return this;
		},

		getNextSection: function(wheelDirection, touchDirection) {
			var self         = this,
			    $nextSection = '';

			// Scroll Direction
			if (wheelDirection || touchDirection == 'up') {
				$nextSection = $('.section-wrapper').eq(self.getCurrentIndex() + 1).find('.section-scroll');
			} else {
				$nextSection = $('.section-wrapper').eq(self.getCurrentIndex() - 1).find('.section-scroll');
			}

			return $nextSection;
		},

		changeSectionActiveState: function($nextSection) {
			var self = this;

			$('.section-wrapper').removeClass('active');
			$nextSection.parent().addClass('active');

			return this;
		},

		changeDotsActiveState: function() {
			var self = this;

			$('.section-scroll-dots-navigation > ul > li').removeClass('active');
			$('.section-scroll-dots-navigation > ul > li').eq(self.getCurrentIndex()).addClass('active');

			return this;
		},

		dotsNavigation: function() {
			var self = this;

			var dotsNav             = $('<div class="section-scroll-dots-navigation"><ul class="list list-unstyled"></ul></div>'),
			    currentSectionIndex = self.getCurrentIndex();

			if (self.options.dotsClass) {
				dotsNav.addClass(self.options.dotsClass);
			}

			for (var i = 0; i < $('.section-scroll').length; i++) {
				var title = $('.section-wrapper').eq(i).find('.section-scroll').data('section-scroll-title');

				dotsNav.find('> ul').append('<li' + ((currentSectionIndex == i) ? ' class="active"' : '') + '><a href="#' + i + '" data-nav-id="' + i + '"><span>' + title + '</span></a></li>');
			}

			$('.body').append(dotsNav);

			dotsNav.find('a[data-nav-id]').on('click touchstart', function(e) {
				e.preventDefault();
				var $this = $(this);

				$('.section-scroll').css({
					opacity:    0,
					transition: 'ease opacity 300ms'
				});

				self.$header.css({
					opacity:    0,
					transition: 'ease opacity 500ms'
				});

				setTimeout(function() {
					self.moveTo($('.section-wrapper').eq($this.data('nav-id')).offset().top);

					$('.section-wrapper').removeClass('active');
					$('.section-wrapper').eq($this.data('nav-id')).addClass('active');

					$('.section-wrapper').eq(self.getCurrentIndex()).find('.section-scroll').css({
						opacity: 1
					});

					setTimeout(function() {
						$('.section-scroll').css({opacity: 1});

						$(window).trigger('section.scroll.change.header.color');
					}, 500);

					self.changeDotsActiveState();
				}, 500);
			});

			return this;
		},

		events: function() {
			var self = this;

			$(window).on('section.scroll.ready', function() {
				$(window).scrollTop(0);
			});

			$(window).on('section.scroll.change.header.color', function() {
				var headerColor = $('.section-wrapper').eq(self.getCurrentIndex()).find('.section-scroll').data('section-scroll-header-color');

				$('#header .header-nav').removeClass('header-nav-light-text header-nav-dark-text').addClass('header-nav-' + headerColor + '-text');
				$('#header .header-nav-features').removeClass('header-nav-features-dark header-nav-features-light').addClass('header-nav-features-' + headerColor);
				$('#header .header-social-icons').removeClass('social-icons-icon-dark social-icons-icon-light').addClass('social-icons-icon-' + headerColor);

				// Change Logo
				if (self.options.changeHeaderLogo && headerColor != undefined) {
					if (headerColor == 'light') {
						$('#header .header-logo img').attr('src', self.options.headerLogoLight);
					} else if (headerColor == 'dark') {
						$('#header .header-logo img').attr('src', self.options.headerLogoDark);
					}
				}

				self.$header.css({
					opacity: 1
				});
			});

			$(document).ready(function() {
				$(window).afterResize(function() {
					self.updateSectionsHeight();
				});
			});

			return this;
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginSectionScroll: PluginSectionScroll
	});

	// jquery plugin
	$.fn.themePluginSectionScroll = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginSectionScroll($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Sort
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__sort';

	var PluginSort = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginSort.defaults = {
		useHash:      true,
		itemSelector: '.isotope-item',
		layoutMode:   'masonry',
		filter:       '*',
		hiddenStyle:  {
			opacity: 0
		},
		visibleStyle: {
			opacity: 1
		},
		stagger:      30,
		isOriginLeft: ($('html').attr('dir') == 'rtl' ? false : true)
	};

	PluginSort.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginSort.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			if (!($.isFunction($.fn.isotope))) {
				return this;
			}

			var self         = this,
			    $source      = this.options.wrapper,
			    $destination = $('.sort-destination[data-sort-id="' + $source.attr('data-sort-id') + '"]'),
			    $window      = $(window);

			if ($destination.get(0)) {

				self.$source = $source;
				self.$destination = $destination;
				self.$loader = false;

				self.setParagraphHeight($destination);

				if (self.$destination.parents('.sort-destination-loader').get(0)) {
					self.$loader = self.$destination.parents('.sort-destination-loader');
					self.createLoader();
				}

				$destination.attr('data-filter', '*');

				$destination.one('layoutComplete', function(event, laidOutItems) {
					self.removeLoader();
				});

				// IE10/11 fix
				if ($('html').hasClass('ie10') || $('html').hasClass('ie11')) {
					var padding = parseInt(self.options.wrapper.children().css('padding-left')) + parseInt(self.options.wrapper.children().css('padding-right'));
				}

				$destination.waitForImages(function() {
					$destination.isotope(self.options);
					self.events();
				});

				setTimeout(function() {
					self.removeLoader();
				}, 3000);

			}

			return this;
		},

		events: function() {
			var self    = this,
			    filter  = null,
			    $window = $(window);

			self.$source.find('a').click(function(e) {
				e.preventDefault();

				filter = $(this).parent().data('option-value');

				self.setFilter(filter);

				if (e.originalEvent) {
					self.$source.trigger('filtered');
				}

				return this;
			});

			self.$destination.trigger('filtered');
			self.$source.trigger('filtered');

			if (self.options.useHash) {
				self.hashEvents();
			}

			$window.on('resize', function() {
				setTimeout(function() {
					self.$destination.isotope('layout');
				}, 300);
			});

			setTimeout(function() {
				$window.trigger('resize');
			}, 300);

			return this;
		},

		setFilter: function(filter) {
			var self          = this,
			    page          = false,
			    currentFilter = filter;

			self.$source.find('.active').removeClass('active');
			self.$source.find('li[data-option-value="' + filter + '"], li[data-option-value="' + filter + '"] > a').addClass('active');

			self.options.filter = currentFilter;

			if (self.$destination.attr('data-current-page')) {
				currentFilter = currentFilter + '[data-page-rel=' + self.$destination.attr('data-current-page') + ']';
			}

			self.$destination.attr('data-filter', filter).isotope({
				filter: currentFilter
			}).one('arrangeComplete', function(event, filteredItems) {

				if (self.options.useHash) {
					if (window.location.hash != '' || self.options.filter.replace('.', '') != '*') {
						window.location.hash = self.options.filter.replace('.', '');
					}
				}

				$(window).trigger('scroll');

			}).trigger('filtered');

			return this;
		},

		hashEvents: function() {
			var self           = this,
			    hash           = null,
			    hashFilter     = null,
			    initHashFilter = '.' + location.hash.replace('#', '');

			if (initHashFilter != '.' && initHashFilter != '.*') {
				self.setFilter(initHashFilter);
			}

			$(window).on('hashchange', function(e) {

				hashFilter = '.' + location.hash.replace('#', '');
				hash = (hashFilter == '.' || hashFilter == '.*' ? '*' : hashFilter);

				self.setFilter(hash);

			});

			return this;
		},

		setParagraphHeight: function() {
			var self               = this,
			    minParagraphHeight = 0,
			    paragraphs         = $('span.thumb-info-caption p', self.$destination);

			paragraphs.each(function() {
				if ($(this).height() > minParagraphHeight) {
					minParagraphHeight = ($(this).height() + 10);
				}
			});

			paragraphs.height(minParagraphHeight);

			return this;
		},

		createLoader: function() {
			var self = this;

			var loaderTemplate = [
				'<div class="bounce-loader">',
				'<div class="bounce1"></div>',
				'<div class="bounce2"></div>',
				'<div class="bounce3"></div>',
				'</div>'
			].join('');

			self.$loader.append(loaderTemplate);

			return this;
		},

		removeLoader: function() {

			var self = this;

			if (self.$loader) {

				self.$loader.removeClass('sort-destination-loader-showing');

				setTimeout(function() {
					self.$loader.addClass('sort-destination-loader-loaded');
				}, 300);

			}

		}

	};

	// expose to scope
	$.extend(theme, {
		PluginSort: PluginSort
	});

	// jquery plugin
	$.fn.themePluginSort = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginSort($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Star Rating
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__starrating';

	var PluginStarRating = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginStarRating.defaults = {
		theme:       'krajee-fas',
		color:       'primary',
		showClear:   false,
		showCaption: false
	};

	PluginStarRating.prototype = {
		initialize: function($el, opts) {
			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginStarRating.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {

			if (!($.isFunction($.fn.rating))) {
				return this;
			}

			var self = this;

			self.options.wrapper
				.rating(self.options);

			self.options.wrapper.parents('.rating-container')
				.addClass('rating-' + self.options.color);

			return this;

		}
	};

	// expose to scope
	$.extend(theme, {
		PluginStarRating: PluginStarRating
	});

	// jquery plugin
	$.fn.themePluginStarRating = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginStarRating($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Sticky
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__sticky';

	var PluginSticky = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginSticky.defaults = {
		minWidth:    991,
		activeClass: 'sticky-active'
	};

	PluginSticky.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build()
				.events();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginSticky.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			if (!($.isFunction($.fn.pin))) {
				return this;
			}

			var self    = this,
			    $window = $(window);

			self.options.wrapper.pin(self.options);

			if (self.options.wrapper.hasClass('sticky-wrapper-transparent')) {
				self.options.wrapper.parent().addClass('position-absolute w-100');
			}

			$window.afterResize(function() {
				self.options.wrapper.removeAttr('style').removeData('pin');
				self.options.wrapper.pin(self.options);
				$window.trigger('scroll');
			});

			// Change Logo Src
			if (self.options.wrapper.find('img').attr('data-change-src')) {
				var $logo      = self.options.wrapper.find('img'),
				    logoSrc    = $logo.attr('src'),
				    logoNewSrc = $logo.attr('data-change-src');

				self.changeLogoSrc = function(activate) {
					if (activate) {
						$logo.attr('src', logoNewSrc);
					} else {
						$logo.attr('src', logoSrc);
					}
				};
			}

			return this;
		},

		events: function() {
			var self                   = this,
			    $window                = $(window),
			    $logo                  = self.options.wrapper.find('img'),
			    sticky_activate_flag   = true,
			    sticky_deactivate_flag = false,
			    class_to_check         = (self.options.wrapper.hasClass('sticky-wrapper-effect-1')) ? 'sticky-effect-active' : 'sticky-active';

			$window.on('scroll sticky.effect.active', function() {
				if (self.options.wrapper.hasClass(class_to_check)) {
					if (sticky_activate_flag) {
						if ($logo.attr('data-change-src')) {
							self.changeLogoSrc(true);
						}

						sticky_activate_flag = false;
						sticky_deactivate_flag = true;
					}
				} else {
					if (sticky_deactivate_flag) {
						if ($logo.attr('data-change-src')) {
							self.changeLogoSrc(false);
						}

						sticky_deactivate_flag = false;
						sticky_activate_flag = true;
					}
				}
			});

			var is_backing = false;
			if (self.options.stickyStartEffectAt) {

				// First Load
				if (self.options.stickyStartEffectAt < $window.scrollTop()) {
					self.options.wrapper.addClass('sticky-effect-active');

					$window.trigger('sticky.effect.active');
				}

				$window.on('scroll', function() {
					if (self.options.stickyStartEffectAt < $window.scrollTop()) {
						self.options.wrapper.addClass('sticky-effect-active');
						is_backing = true;

						$window.trigger('sticky.effect.active');
					} else {
						if (is_backing) {
							self.options.wrapper.find('.sticky-body').addClass('position-fixed');
							is_backing = false;
						}

						if ($window.scrollTop() == 0) {
							self.options.wrapper.find('.sticky-body').removeClass('position-fixed');
						}

						self.options.wrapper.removeClass('sticky-effect-active');
					}
				});
			}
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginSticky: PluginSticky
	});

	// jquery plugin
	$.fn.themePluginSticky = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginSticky($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Toggle
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__toggle';

	var PluginToggle = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginToggle.defaults = {
		duration:    350,
		isAccordion: false
	};

	PluginToggle.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginToggle.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			var self     = this,
			    $wrapper = this.options.wrapper,
			    $items   = $wrapper.find('> .toggle'),
			    $el      = null;

			$items.each(function() {
				$el = $(this);

				if ($el.hasClass('active')) {
					$el.find('> p').addClass('preview-active');
					$el.find('> .toggle-content').slideDown(self.options.duration);
				}

				self.events($el);
			});

			if (self.options.isAccordion) {
				self.options.duration = self.options.duration / 2;
			}

			return this;
		},

		events: function($el) {
			var self                    = this,
			    previewParCurrentHeight = 0,
			    previewParAnimateHeight = 0,
			    toggleContent           = null;

			$el.find('> label, > .toggle-title').click(function(e) {

				var $this         = $(this),
				    parentSection = $this.parent(),
				    parentWrapper = $this.parents('.toggle'),
				    previewPar    = null,
				    closeElement  = null;

				if (self.options.isAccordion && typeof (e.originalEvent) != 'undefined') {
					closeElement = parentWrapper.find('.toggle.active > label, .toggle.active > .toggle-title');

					if (closeElement[0] == $this[0]) {
						return;
					}
				}

				parentSection.toggleClass('active');

				// Preview Paragraph
				if (parentSection.find('> p').get(0)) {

					previewPar = parentSection.find('> p');
					previewParCurrentHeight = previewPar.css('height');
					previewPar.css('height', 'auto');
					previewParAnimateHeight = previewPar.css('height');
					previewPar.css('height', previewParCurrentHeight);

				}

				// Content
				toggleContent = parentSection.find('> .toggle-content');

				if (parentSection.hasClass('active')) {

					$(previewPar).animate({
						height: previewParAnimateHeight
					}, self.options.duration, function() {
						$(this).addClass('preview-active');
					});

					toggleContent.slideDown(self.options.duration, function() {
						if (closeElement) {
							closeElement.trigger('click');
						}
					});

				} else {

					$(previewPar).animate({
						height: 0
					}, self.options.duration, function() {
						$(this).removeClass('preview-active');
					});

					toggleContent.slideUp(self.options.duration);

				}

			});
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginToggle: PluginToggle
	});

	// jquery plugin
	$.fn.themePluginToggle = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginToggle($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Tweets
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__tweets';

	var PluginTweets = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginTweets.defaults = {
		username:  null,
		count:     2,
		URL:       'php/twitter-feed.php',
		iconColor: false
	};

	PluginTweets.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginTweets.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			if (this.options.username == null || this.options.username == '') {
				return this;
			}

			var self     = this,
			    $wrapper = this.options.wrapper;

			$.ajax({
				type: 'GET',
				data: {
					twitter_screen_name: self.options.username,
					tweets_to_display:   self.options.count,
					icon_color:          self.options.iconColor
				},
				url:  self.options.URL,
			}).done(function(html) {
				$wrapper.html(html).find('a').attr('target', '_blank');
			});

			return this;
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginTweets: PluginTweets
	});

	// jquery plugin
	$.fn.themePluginTweets = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginTweets($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Validation
(function(theme, $) {

	let initialized = false;

	theme = theme || {};

	$.extend(theme, {

		PluginValidation: {

			defaults: {
				formClass:          'needs-validation',
				validator:          {
					highlight:      function(element) {
						$(element)
							.addClass('is-invalid')
							.removeClass('is-valid')
							.parent()
							.removeClass('has-success')
							.addClass('has-danger');
					},
					success:        function(label, element) {
						$(element)
							.removeClass('is-invalid')
							.addClass('is-valid')
							.parent()
							.removeClass('has-danger')
							.addClass('has-success')
							.find('label.error')
							.remove();
					},
					errorPlacement: function(error, element) {
						if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
							error.appendTo(element.parent().parent());
						} else {
							error.insertAfter(element);
						}
					}
				},
				validateCaptchaURL: 'php/contact-form-verify-captcha.php',
				refreshCaptchaURL:  'php/contact-form-refresh-captcha.php'
			},

			initialize: function(opts) {
				initialized = true;

				this
					.setOptions(opts)
					.build();

				return this;
			},

			setOptions: function(opts) {
				this.options = $.extend(true, {}, this.defaults, opts);

				return this;
			},

			build: function() {
				var self = this;

				if (!($.isFunction($.validator))) {
					return this;
				}

				self.addMethods();
				self.setMessageGroups();

				$.validator.setDefaults(self.options.validator);

				$('.' + self.options.formClass).validate();

				return this;
			},

			addMethods: function() {
				var self = this;

				$.validator.addMethod('captcha', function(value, element, params) {
					var captchaValid = false;

					$.ajax({
						url:      self.options.validateCaptchaURL,
						type:     'POST',
						async:    false,
						dataType: 'json',
						data:     {
							captcha: $.trim(value)
						},
						success:  function(data) {
							if (data.response == 'success') {
								captchaValid = true;
							}
						}
					});

					if (captchaValid) {
						return true;
					}

				}, '');

				// Refresh Captcha
				$('#refreshCaptcha').on('click', function(e) {
					e.preventDefault();
					$.get(self.options.refreshCaptchaURL, function(url) {
						$('#captcha-image').attr('src', url);
					});
				});

			},

			setMessageGroups: function() {

				$('.checkbox-group[data-msg-required], .radio-group[data-msg-required]').each(function() {
					var message = $(this).data('msg-required');
					$(this).find('input').attr('data-msg-required', message);
				});

			}

		}

	});

}).apply(this, [window.theme, jQuery]);

// Video Background
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__videobackground';

	var PluginVideoBackground = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginVideoBackground.defaults = {
		overlay:      false,
		volume:       1,
		playbackRate: 1,
		muted:        true,
		loop:         true,
		autoplay:     true,
		position:     '50% 50%',
		posterType:   'detect'
	};

	PluginVideoBackground.prototype = {
		initialize: function($el, opts) {
			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginVideoBackground.defaults, opts, {
				path:    this.$el.data('video-path'),
				wrapper: this.$el
			});

			return this;
		},

		build: function() {

			if (!($.isFunction($.fn.vide)) || (!this.options.path)) {
				return this;
			}

			if (this.options.overlay) {

				var overlayClass = this.options.overlayClass;

				this.options.wrapper.prepend(
					$('<div />').addClass(overlayClass)
				);
			}

			this.options.wrapper
				.vide(this.options.path, this.options)
				.first().css('z-index', 0);

			return this;
		}
	};

	// expose to scope
	$.extend(theme, {
		PluginVideoBackground: PluginVideoBackground
	});

	// jquery plugin
	$.fn.themePluginVideoBackground = function(opts) {
		return this.map(function() {
			var $this = $(this);

			if ($this.data(instanceName)) {
				return $this.data(instanceName);
			} else {
				return new PluginVideoBackground($this, opts);
			}

		});
	};

}).apply(this, [window.theme, jQuery]);

// Account
(function(theme, $) {

	theme = theme || {};

	var initialized = false;

	$.extend(theme, {

		Account: {

			defaults: {
				wrapper: $('#headerAccount')
			},

			initialize: function($wrapper, opts) {
				if (initialized) {
					return this;
				}

				initialized = true;
				this.$wrapper = ($wrapper || this.defaults.wrapper);

				this
					.setOptions(opts)
					.events();

				return this;
			},

			setOptions: function(opts) {
				this.options = $.extend(true, {}, this.defaults, opts, theme.fn.getOptions(this.$wrapper.data('plugin-options')));

				return this;
			},

			events: function() {
				var self = this;

				$(window).on('load', function() {
					$(document).ready(function() {
						setTimeout(function() {

							self.$wrapper.find('input').on('focus', function() {
								self.$wrapper.addClass('open');

								$(document).mouseup(function(e) {
									if (!self.$wrapper.is(e.target) && self.$wrapper.has(e.target).length === 0) {
										self.$wrapper.removeClass('open');
									}
								});
							});

						}, 1500);
					});
				});

				$('#headerSignUp').on('click', function(e) {
					e.preventDefault();
					self.$wrapper.addClass('signup').removeClass('signin').removeClass('recover');
					self.$wrapper.find('.signup-form input:first').focus();
				});

				$('#headerSignIn').on('click', function(e) {
					e.preventDefault();
					self.$wrapper.addClass('signin').removeClass('signup').removeClass('recover');
					self.$wrapper.find('.signin-form input:first').focus();
				});

				$('#headerRecover').on('click', function(e) {
					e.preventDefault();
					self.$wrapper.addClass('recover').removeClass('signup').removeClass('signin');
					self.$wrapper.find('.recover-form input:first').focus();
				});

				$('#headerRecoverCancel').on('click', function(e) {
					e.preventDefault();
					self.$wrapper.addClass('signin').removeClass('signup').removeClass('recover');
					self.$wrapper.find('.signin-form input:first').focus();
				});
			}

		}

	});

}).apply(this, [window.theme, jQuery]);

// Nav
(function(theme, $) {

	theme = theme || {};

	var initialized = false;

	$.extend(theme, {

		Nav: {

			defaults: {
				wrapper:         $('#mainNav'),
				scrollDelay:     600,
				scrollAnimation: 'easeOutQuad'
			},

			initialize: function($wrapper, opts) {
				if (initialized) {
					return this;
				}

				initialized = true;
				this.$wrapper = ($wrapper || this.defaults.wrapper);

				this
					.setOptions(opts)
					.build()
					.events();

				return this;
			},

			setOptions: function(opts) {
				this.options = $.extend(true, {}, this.defaults, opts, theme.fn.getOptions(this.$wrapper.data('plugin-options')));

				return this;
			},

			build: function() {
				var self           = this,
				    $html          = $('html'),
				    $header        = $('#header'),
				    $headerNavMain = $('#header .header-nav-main'),
				    thumbInfoPreview;

				// Preview Thumbs
				self.$wrapper.find('a[data-thumb-preview]').each(function() {
					thumbInfoPreview = $('<span />').addClass('thumb-info thumb-info-preview')
						.append($('<span />').addClass('thumb-info-wrapper')
							.append($('<span />').addClass('thumb-info-image').css('background-image', 'url(' + $(this).data('thumb-preview') + ')')
							)
						);

					$(this).append(thumbInfoPreview);
				});

				// Side Header / Side Header Hamburguer Sidebar (Reverse Dropdown)
				if ($html.hasClass('side-header') || $html.hasClass('side-header-hamburguer-sidebar')) {

					// Side Header Right / Side Header Hamburguer Sidebar Right
					if ($html.hasClass('side-header-right') || $html.hasClass('side-header-hamburguer-sidebar-right')) {
						if (!$html.hasClass('side-header-right-no-reverse')) {
							$header.find('.dropdown-submenu').addClass('dropdown-reverse');
						}
					}

				} else {

					// Reverse
					self.checkReverse = function() {
						self.$wrapper.find('.dropdown, .dropdown-submenu').removeClass('dropdown-reverse');

						self.$wrapper.find('.dropdown:not(.manual):not(.dropdown-mega), .dropdown-submenu:not(.manual)').each(function() {
							if (!$(this).find('.dropdown-menu').visible(false, true, 'horizontal')) {
								$(this).addClass('dropdown-reverse');
							}
						});
					};

					self.checkReverse();

					$(window).on('resize', function() {
						self.checkReverse();
					});

				}

				// Clone Items
				if ($headerNavMain.hasClass('header-nav-main-clone-items')) {

					$headerNavMain.find('nav > ul > li > a').each(function() {
						var parent  = $(this).parent(),
						    clone   = $(this).clone(),
						    clone2  = $(this).clone(),
						    wrapper = $('<span class="wrapper-items-cloned"></span>');

						// Config Classes
						$(this).addClass('item-original');
						clone2.addClass('item-two');

						// Insert on DOM
						parent.prepend(wrapper);
						wrapper.append(clone).append(clone2);
					});

				}

				// Floating
				if ($('#header.header-floating-icons').get(0) && $(window).width() > 991) {

					var menuFloatingAnim = {
						$menuFloating: $('#header.header-floating-icons .header-container > .header-row'),

						build: function() {
							var self = this;

							self.init();
						},
						init:  function() {
							var self    = this,
							    divisor = 0;

							$(window).scroll(function() {
								var scrollPercent = 100 * $(window).scrollTop() / ($(document).height() - $(window).height()),
								    st            = $(this).scrollTop();

								divisor = $(document).height() / $(window).height();

								self.$menuFloating.find('.header-column > .header-row').css({
									transform: 'translateY( calc(' + scrollPercent + 'vh - ' + st / divisor + 'px) )'
								});
							});
						}
					};

					menuFloatingAnim.build();

				}

				// Slide
				if ($('.header-nav-links-vertical-slide').get(0)) {
					var slideNavigation = {
						$mainNav:     $('#mainNav'),
						$mainNavItem: $('#mainNav li'),

						build:   function() {
							var self = this;

							self.menuNav();
						},
						menuNav: function() {
							var self = this;

							self.$mainNavItem.on('click', function(e) {
								var currentMenuItem    = $(this),
								    currentMenu        = $(this).parent(),
								    nextMenu           = $(this).find('ul').first(),
								    prevMenu           = $(this).closest('.next-menu'),
								    isSubMenu          = currentMenuItem.hasClass('dropdown') || currentMenuItem.hasClass('dropdown-submenu'),
								    isBack             = currentMenuItem.hasClass('back-button'),
								    nextMenuHeightDiff = ((nextMenu.find('> li').length * nextMenu.find('> li').outerHeight()) - nextMenu.outerHeight()),
								    prevMenuHeightDiff = ((prevMenu.find('> li').length * prevMenu.find('> li').outerHeight()) - prevMenu.outerHeight());

								if (isSubMenu) {
									currentMenu.addClass('next-menu');
									nextMenu.addClass('visible');
									currentMenu.css({
										overflow:     'visible',
										'overflow-y': 'visible'
									});

									if (nextMenuHeightDiff > 0) {
										nextMenu.css({
											overflow:     'hidden',
											'overflow-y': 'scroll'
										});
									}

									for (let i = 0; i < nextMenu.find('> li').length; i++) {
										if (nextMenu.outerHeight() < ($('.header-row-side-header').outerHeight() - 100)) {
											nextMenu.css({
												height: nextMenu.outerHeight() + nextMenu.find('> li').outerHeight()
											});
										}
									}

									nextMenu.css({
										'padding-top': nextMenuHeightDiff + 'px'
									});
								}

								if (isBack) {
									currentMenu.parent().parent().removeClass('next-menu');
									currentMenu.removeClass('visible');

									if (prevMenuHeightDiff > 0) {
										prevMenu.css({
											overflow:     'hidden',
											'overflow-y': 'scroll'
										});
									}
								}

								e.stopPropagation();
							});
						}
					};

					$(window).trigger('resize');

					if ($(window).width() > 991) {
						slideNavigation.build();
					}

					$(document).ready(function() {
						$(window).afterResize(function() {
							if ($(window).width() > 991) {
								slideNavigation.build();
							}
						});
					});
				}

				// Header Nav Main Mobile Dark
				if ($('.header-nav-main-mobile-dark').get(0)) {
					$('#header:not(.header-transparent-dark-bottom-border):not(.header-transparent-light-bottom-border)').addClass('header-no-border-bottom');
				}

				return this;
			},

			events: function() {
				var self             = this,
				    $html            = $('html'),
				    $header          = $('#header'),
				    $window          = $(window),
				    headerBodyHeight = $('.header-body').outerHeight();

				$header.find('a[href="#"]').on('click', function(e) {
					e.preventDefault();
				});

				// Mobile Arrows
				$header.find('.dropdown-toggle, .dropdown-submenu > a')
					.append('<i class="fa-light fa-chevron-down"></i>');

				$header.find('.dropdown-toggle[href="#"], .dropdown-submenu a[href="#"], .dropdown-toggle[href!="#"] .fa-chevron-down, .dropdown-submenu a[href!="#"] .fa-chevron-down').on('click', function(e) {
					e.preventDefault();
					if ($window.width() < 992) {
						$(this).closest('li').toggleClass('open');

						// Adjust Header Body Height
						var height = ($header.hasClass('header-effect-shrink') && $html.hasClass('sticky-header-active')) ? theme.StickyHeader.options.stickyHeaderContainerHeight : headerBodyHeight;
						$('.header-body').animate({
							height: ($('.header-nav-main nav').outerHeight(true) + height) + 10
						}, 0);
					}
				});

				$header.find('li a.active').addClass('current-page-active');

				// Add Open Class
				$header.find('.header-nav-click-to-open .dropdown-toggle[href="#"], .header-nav-click-to-open .dropdown-submenu a[href="#"], .header-nav-click-to-open .dropdown-toggle > i').on('click', function(e) {
					e.preventDefault();
					e.stopPropagation();
					if ($window.width() > 991) {

						$header.find('li a.active').removeClass('active');

						if ($(this).prop('tagName') == 'I') {
							$(this).parent().addClass('active');
						} else {
							$(this).addClass('active');
						}

						if (!$(this).closest('li').hasClass('open')) {

							var $li   = $(this).closest('li'),
							    isSub = false;

							if ($(this).parent().hasClass('dropdown-submenu')) {
								isSub = true;
							}

							$(this).closest('.dropdown-menu').find('.dropdown-submenu.open').removeClass('open');
							$(this).parent('.dropdown').parent().find('.dropdown.open').removeClass('open');

							if (!isSub) {
								$(this).parent().find('.dropdown-submenu.open').removeClass('open');
							}

							$li.addClass('open');

							$(document).off('click.nav-click-to-open').on('click.nav-click-to-open', function(e) {
								if (!$li.is(e.target) && $li.has(e.target).length === 0) {
									$li.removeClass('open');
									$li.parents('.open').removeClass('open');
									$header.find('li a.active').removeClass('active');
									$header.find('li a.current-page-active').addClass('active');
								}
							});

						} else {
							$(this).closest('li').removeClass('open');
							$header.find('li a.active').removeClass('active');
							$header.find('li a.current-page-active').addClass('active');
						}

						$window.trigger({
							type: 'resize',
							from: 'header-nav-click-to-open'
						});
					}
				});

				// Collapse Nav
				$header.find('[data-collapse-nav]').on('click', function(e) {
					$(this).parents('.collapse').removeClass('show');
				});

				// Top Features
				$header.find('.header-nav-features-toggle').on('click', function(e) {
					e.preventDefault();

					var $toggleParent = $(this).parent();

					if (!$(this).siblings('.header-nav-features-dropdown').hasClass('show')) {

						var $dropdown = $(this).siblings('.header-nav-features-dropdown');

						$('.header-nav-features-dropdown.show').removeClass('show');

						$dropdown.addClass('show');

						$(document).off('click.header-nav-features-toggle').on('click.header-nav-features-toggle', function(e) {
							if (!$toggleParent.is(e.target) && $toggleParent.has(e.target).length === 0) {
								$('.header-nav-features-dropdown.show').removeClass('show');
							}
						});

						if ($(this).attr('data-focus')) {
							$('#' + $(this).attr('data-focus')).focus();
						}

					} else {
						$(this).siblings('.header-nav-features-dropdown').removeClass('show');
					}
				});

				// Hamburguer Menu
				var $hamburguerMenuBtn    = $('.hamburguer-btn:not(.side-panel-toggle)'),
				    $hamburguerSideHeader = $('#header.side-header, #header.side-header-overlay-full-screen');

				$hamburguerMenuBtn.on('click', function() {
					if ($(this).attr('data-set-active') != 'false') {
						$(this).toggleClass('active');
					}
					$hamburguerSideHeader.toggleClass('side-header-hide');
					$html.toggleClass('side-header-hide');

					$window.trigger('resize');
				});

				$('.hamburguer-close:not(.side-panel-toggle)').on('click', function() {
					$('.hamburguer-btn:not(.hamburguer-btn-side-header-mobile-show)').trigger('click');
				});

				// Set Header Body Height when open mobile menu
				$('.header-nav-main nav').on('show.bs.collapse', function() {
					$(this).removeClass('closed');

					// Add Mobile Menu Opened Class
					$('html').addClass('mobile-menu-opened');

					$('.header-body').animate({
						height: ($('.header-body').outerHeight() + $('.header-nav-main nav').outerHeight(true)) + 10
					});

					// Header Below Slider / Header Bottom Slider - Scroll to menu position
					if ($('#header').is('.header-bottom-slider, .header-below-slider') && !$('html').hasClass('sticky-header-active')) {
						self.scrollToTarget($('#header'), 0);
					}
				});

				// Set Header Body Height when collapse mobile menu
				$('.header-nav-main nav').on('hide.bs.collapse', function() {
					$(this).addClass('closed');

					// Remove Mobile Menu Opened Class
					$('html').removeClass('mobile-menu-opened');

					$('.header-body').animate({
						height: ($('.header-body').outerHeight() - $('.header-nav-main nav').outerHeight(true))
					}, function() {
						$(this).height('auto');
					});
				});

				// Header Effect Shrink - Adjust header body height on mobile
				$window.on('stickyHeader.activate', function() {
					if ($window.width() < 992 && $header.hasClass('header-effect-shrink')) {
						if ($('.header-btn-collapse-nav').attr('aria-expanded') == 'true') {
							$('.header-body').animate({
								height: ($('.header-nav-main nav').outerHeight(true) + theme.StickyHeader.options.stickyHeaderContainerHeight) + (($('.header-nav-bar').get(0)) ? $('.header-nav-bar').outerHeight() : 0)
							});
						}
					}
				});

				$window.on('stickyHeader.deactivate', function() {
					if ($window.width() < 992 && $header.hasClass('header-effect-shrink')) {
						if ($('.header-btn-collapse-nav').attr('aria-expanded') == 'true') {
							$('.header-body').animate({
								height: headerBodyHeight + $('.header-nav-main nav').outerHeight(true) + 10
							});
						}
					}
				});

				// Remove Open Class on Resize		
				$window.on('resize.removeOpen', function(e) {
					if (e.from == 'header-nav-click-to-open') {
						return;
					}

					setTimeout(function() {
						if ($window.width() > 991) {
							$header.find('.dropdown.open').removeClass('open');
						}
					}, 100);
				});

				// Side Header - Change value of initial header body height
				$(document).ready(function() {
					if ($window.width() > 991) {
						var flag = false;

						$window.on('resize', function(e) {
							if (e.from == 'header-nav-click-to-open') {
								return;
							}

							$header.find('.dropdown.open').removeClass('open');

							if ($window.width() < 992 && flag == false) {
								headerBodyHeight = $('.header-body').outerHeight();
								flag = true;

								setTimeout(function() {
									flag = false;
								}, 500);
							}
						});
					}
				});

				// Side Header - Set header height on mobile
				if ($html.hasClass('side-header')) {
					if ($window.width() < 992) {
						$header.css({
							height: $('.header-body .header-container').outerHeight() + (parseInt($('.header-body').css('border-top-width')) + parseInt($('.header-body').css('border-bottom-width')))
						});
					}

					$(document).ready(function() {
						$window.afterResize(function() {
							if ($window.width() < 992) {
								$header.css({
									height: $('.header-body .header-container').outerHeight() + (parseInt($('.header-body').css('border-top-width')) + parseInt($('.header-body').css('border-bottom-width')))
								});
							} else {
								$header.css({
									height: ''
								});
							}
						});
					});
				}

				// Anchors Position
				$('[data-hash]').each(function() {

					var target = $(this).attr('href'),
					    offset = ($(this).is('[data-hash-offset]') ? $(this).data('hash-offset') : 0);

					if ($(target).get(0)) {
						$(this).on('click', function(e) {
							e.preventDefault();

							if (!$(e.target).is('i')) {

								// Close Collapse if open
								$(this).parents('.collapse.show').collapse('hide');

								// Close Side Header
								$hamburguerSideHeader.addClass('side-header-hide');
								$html.addClass('side-header-hide');

								$window.trigger('resize');

								self.scrollToTarget(target, offset);

							}

							return;
						});
					}

				});

				// Floating
				if ($('#header.header-floating-icons').get(0)) {

					$('#header.header-floating-icons [data-hash]').off().each(function() {

						var target = $(this).attr('href'),
						    offset = ($(this).is('[data-hash-offset]') ? $(this).data('hash-offset') : 0);

						if ($(target).get(0)) {
							$(this).on('click', function(e) {
								e.preventDefault();

								$('html, body').animate({
									scrollTop: $(target).offset().top - offset
								}, 600, 'easeOutQuad', function() {

								});

								return;
							});
						}

					});

				}

				// Side Panel Toggle
				if ($('.side-panel-toggle').get(0)) {
					var init_html_class = $('html').attr('class');

					$('.side-panel-toggle').on('click', function(e) {
						var extra_class = $(this).data('extra-class'),
						    delay       = (extra_class) ? 100 : 0;

						e.preventDefault();

						if ($(this).hasClass('active')) {
							$('html').removeClass('side-panel-open');
							$('.hamburguer-btn.side-panel-toggle:not(.side-panel-close)').removeClass('active');
							return false;
						}

						if (extra_class) {
							$('.side-panel-wrapper').css('transition', 'none');
							$('html')
								.removeClass()
								.addClass(init_html_class)
								.addClass(extra_class);
						}

						setTimeout(function() {
							$('.side-panel-wrapper').css('transition', '');
							$('html').toggleClass('side-panel-open');
						}, delay);
					});

					$(document).on('click', function(e) {
						if (!$(e.target).closest('.side-panel-wrapper').get(0) && !$(e.target).hasClass('side-panel-toggle')) {
							$('.hamburguer-btn.side-panel-toggle:not(.side-panel-close)').removeClass('active');
							$('html').removeClass('side-panel-open');
						}
					});
				}

				return this;
			},

			scrollToTarget: function(target, offset) {
				var self = this;

				$('body').addClass('scrolling');

				$('html, body').animate({
					scrollTop: $(target).offset().top - offset
				}, self.options.scrollDelay, self.options.scrollAnimation, function() {
					$('body').removeClass('scrolling');
				});

				return this;

			}

		}

	});

}).apply(this, [window.theme, jQuery]);

// Newsletter
(function(theme, $) {

	theme = theme || {};

	var initialized = false;

	$.extend(theme, {

		Newsletter: {

			defaults: {
				wrapper: $('#newsletterForm')
			},

			initialize: function($wrapper, opts) {
				if (initialized) {
					return this;
				}

				initialized = true;
				this.$wrapper = ($wrapper || this.defaults.wrapper);

				this
					.setOptions(opts)
					.build();

				return this;
			},

			setOptions: function(opts) {
				this.options = $.extend(true, {}, this.defaults, opts, theme.fn.getOptions(this.$wrapper.data('plugin-options')));

				return this;
			},

			build: function() {
				if (!($.isFunction($.fn.validate))) {
					return this;
				}

				var self     = this,
				    $email   = self.$wrapper.find('#newsletterEmail'),
				    $success = $('#newsletterSuccess'),
				    $error   = $('#newsletterError');

				self.$wrapper.validate({
					submitHandler:  function(form) {

						$.ajax({
							type:     'POST',
							url:      self.$wrapper.attr('action'),
							data:     {
								'email': $email.val()
							},
							dataType: 'json',
							success:  function(data) {
								if (data.response == 'success') {

									$success.removeClass('d-none');
									$error.addClass('d-none');

									$email
										.val('')
										.blur()
										.closest('.control-group')
										.removeClass('success')
										.removeClass('error');

								} else {

									$error.html(data.message);
									$error.removeClass('d-none');
									$success.addClass('d-none');

									$email
										.blur()
										.closest('.control-group')
										.removeClass('success')
										.addClass('error');

								}
							}
						});

					},
					rules:          {
						newsletterEmail: {
							required: true,
							email:    true
						}
					},
					errorPlacement: function(error, element) {

					}
				});

				return this;
			}

		}

	});

}).apply(this, [window.theme, jQuery]);

// Search
(function(theme, $) {

	theme = theme || {};

	var initialized = false;

	$.extend(theme, {

		Search: {

			defaults: {
				wrapper: $('#searchForm')
			},

			initialize: function($wrapper, opts) {
				if (initialized) {
					return this;
				}

				initialized = true;
				this.$wrapper = ($wrapper || this.defaults.wrapper);

				this
					.setOptions(opts)
					.build();

				return this;
			},

			setOptions: function(opts) {
				this.options = $.extend(true, {}, this.defaults, opts, theme.fn.getOptions(this.$wrapper.data('plugin-options')));

				return this;
			},

			build: function() {
				if (!($.isFunction($.fn.validate))) {
					return this;
				}

				this.$wrapper.validate({
					errorPlacement: function(error, element) {}
				});

				// Search Reveal
				$('.header-nav-features-search-reveal').each(function() {

					var $el     = $(this),
					    $header = $('#header'),
					    $html   = $('htmnl');

					$el.find('.header-nav-features-search-show-icon').on('click', function() {
						$el.addClass('show');
						$header.addClass('search-show');
						$html.addClass('search-show');
						$('#headerSearch').focus();
					});

					$el.find('.header-nav-features-search-hide-icon').on('click', function() {
						$el.removeClass('show');
						$header.removeClass('search-show');
						$html.removeClass('search-show');
					});

				});

				return this;
			}

		}

	});

}).apply(this, [window.theme, jQuery]);

// Sticky Header
(function(theme, $) {

	theme = theme || {};

	var initialized = false;

	$.extend(theme, {

		StickyHeader: {

			defaults: {
				wrapper:                     $('#header'),
				headerBody:                  $('#header .header-body'),
				stickyEnabled:               true,
				stickyEnableOnBoxed:         true,
				stickyEnableOnMobile:        true,
				stickyStartAt:               0,
				stickyStartAtElement:        false,
				stickySetTop:                0,
				stickyEffect:                '',
				stickyHeaderContainerHeight: false,
				stickyChangeLogo:            false,
				stickyChangeLogoWrapper:     true
			},

			initialize: function($wrapper, opts) {
				if (initialized) {
					return this;
				}

				initialized = true;
				this.$wrapper = ($wrapper || this.defaults.wrapper);

				this
					.setOptions(opts)
					.build()
					.events();

				return this;
			},

			setOptions: function(opts) {
				this.options = $.extend(true, {}, this.defaults, opts, theme.fn.getOptions(this.$wrapper.data('plugin-options')));

				return this;
			},

			build: function() {
				if (!this.options.stickyEnableOnBoxed && $('html').hasClass('boxed') || $('html').hasClass('side-header-hamburguer-sidebar') || !this.options.stickyEnabled) {
					return this;
				}

				var self                         = this,
				    $html                        = $('html'),
				    $window                      = $(window),
				    sideHeader                   = $html.hasClass('side-header'),
				    initialHeaderTopHeight       = self.options.wrapper.find('.header-top').outerHeight(),
				    initialHeaderContainerHeight = self.options.wrapper.find('.header-container').outerHeight(),
				    minHeight;

				// HTML Classes
				$html.addClass('sticky-header-enabled');

				if (parseInt(self.options.stickySetTop) < 0) {
					$html.addClass('sticky-header-negative');
				}

				// Set Start At
				if (self.options.stickyStartAtElement) {

					var $stickyStartAtElement = $(self.options.stickyStartAtElement);

					$(window).on('scroll resize', function() {
						self.options.stickyStartAt = $stickyStartAtElement.offset().top;
					});

					$(window).trigger('resize');
				}

				// Define Min Height value
				if (self.options.wrapper.find('.header-top').get(0)) {
					minHeight = (initialHeaderTopHeight + initialHeaderContainerHeight);
				} else {
					minHeight = initialHeaderContainerHeight;
				}

				// Set Wrapper Min-Height
				if (!sideHeader) {
					if (!$('.header-logo-sticky-change').get(0)) {
						self.options.wrapper.css('height', self.options.headerBody.outerHeight());
					} else {
						$window.on('stickyChangeLogo.loaded', function() {
							self.options.wrapper.css('height', self.options.headerBody.outerHeight());
						});
					}

					if (self.options.stickyEffect == 'shrink') {

						// Prevent wrong visualization of header when reload on middle of page
						$(document).ready(function() {
							if ($window.scrollTop() >= self.options.stickyStartAt) {
								self.options.wrapper.find('.header-container').on('transitionend webkitTransitionEnd oTransitionEnd', function() {
									self.options.headerBody.css('position', 'fixed');
								});
							} else {
								self.options.headerBody.css('position', 'fixed');
							}
						});

						self.options.wrapper.find('.header-container').css('height', initialHeaderContainerHeight);
						self.options.wrapper.find('.header-top').css('height', initialHeaderTopHeight);
					}
				}

				// Sticky Header Container Height
				if (self.options.stickyHeaderContainerHeight) {
					self.options.wrapper.find('.header-container').css('height', self.options.wrapper.find('.header-container').outerHeight());
				}

				// Boxed
				if ($html.hasClass('boxed') && self.options.stickyEffect == 'shrink') {
					if ((parseInt(self.options.stickyStartAt) == 0) && $window.width() > 991) {
						self.options.stickyStartAt = 30;
					}

					// Set Header Body Position Absolute
					self.options.headerBody.css('position', 'absolute');

					// Set position absolute because top margin from boxed layout
					$window.on('scroll', function() {
						if ($window.scrollTop() > $('.body').offset().top) {
							self.options.headerBody.css({
								'position': 'fixed',
								'top':      0
							});
						} else {
							self.options.headerBody.css({
								'position': 'absolute',
								'top':      0
							});
						}
					});
				}

				// Check Sticky Header / Flags prevent multiple runs at same time
				var activate_flag   = true,
				    deactivate_flag = false;

				self.checkStickyHeader = function() {
					if ($window.width() > 991 && $html.hasClass('side-header')) {
						$html.removeClass('sticky-header-active');
						activate_flag = true;
						return;
					}

					if ($window.scrollTop() >= parseInt(self.options.stickyStartAt)) {
						if (activate_flag) {
							self.activateStickyHeader();
							activate_flag = false;
							deactivate_flag = true;
						}
					} else {
						if (deactivate_flag) {
							self.deactivateStickyHeader();
							deactivate_flag = false;
							activate_flag = true;
						}
					}
				};

				// Activate Sticky Header
				self.activateStickyHeader = function() {

					if ($window.width() < 992) {
						if (!self.options.stickyEnableOnMobile) {
							self.deactivateStickyHeader();
							return;
						}
					} else {
						if (sideHeader) {
							self.deactivateStickyHeader();
							return;
						}
					}

					$html.addClass('sticky-header-active');

					// Sticky Effect - Reveal
					if (self.options.stickyEffect == 'reveal') {

						self.options.headerBody.css('top', '-' + self.options.stickyStartAt + 'px');

						self.options.headerBody.animate({
							top: self.options.stickySetTop
						}, 400, function() {});

					}

					// Sticky Effect - Shrink
					if (self.options.stickyEffect == 'shrink') {

						// If Header Top
						if (self.options.wrapper.find('.header-top').get(0)) {
							self.options.wrapper.find('.header-top').css({
								height:       0,
								'min-height': 0,
								overflow:     'hidden'
							});
						}

						// Header Container
						if (self.options.stickyHeaderContainerHeight) {
							self.options.wrapper.find('.header-container').css({
								height:       self.options.stickyHeaderContainerHeight,
								'min-height': 0
							});
						} else {
							self.options.wrapper.find('.header-container').css({
								height:       (initialHeaderContainerHeight / 3) * 2, // two third of container height
								'min-height': 0
							});

							var y = initialHeaderContainerHeight - ((initialHeaderContainerHeight / 3) * 2);
							$('.main').css({
								transform:  'translate3d(0, -' + y + 'px, 0)',
								transition: 'ease transform 300ms'
							});

							if ($html.hasClass('boxed')) {
								self.options.headerBody.css('position', 'fixed');
							}
						}

					}

					self.options.headerBody.css('top', self.options.stickySetTop);

					if (self.options.stickyChangeLogo) {
						self.changeLogo(true);
					}

					// Set Elements Style
					$('[data-sticky-header-style]').each(function() {
						var $el  = $(this),
						    css  = theme.fn.getOptions($el.data('sticky-header-style-active')),
						    opts = theme.fn.getOptions($el.data('sticky-header-style'));

						if ($window.width() > opts.minResolution) {
							$el.css(css);
						}
					});

					$.event.trigger({
						type: 'stickyHeader.activate'
					});
				};

				// Deactivate Sticky Header
				self.deactivateStickyHeader = function() {

					$html.removeClass('sticky-header-active');

					// Sticky Effect - Shrink
					if (self.options.stickyEffect == 'shrink') {

						// Boxed Layout
						if ($html.hasClass('boxed')) {

							// Set Header Body Position Absolute
							self.options.headerBody.css('position', 'absolute');

							if ($window.scrollTop() > $('.body').offset().top) {
								// Set Header Body Position Fixed
								self.options.headerBody.css('position', 'fixed');
							}

						} else {
							// Set Header Body Position Fixed
							self.options.headerBody.css('position', 'fixed');
						}

						// If Header Top
						if (self.options.wrapper.find('.header-top').get(0)) {
							self.options.wrapper.find('.header-top').css({
								height:   initialHeaderTopHeight,
								overflow: 'visible'
							});
						}

						// Header Container
						self.options.wrapper.find('.header-container').css({
							height: initialHeaderContainerHeight
						});

					}

					self.options.headerBody.css('top', 0);

					if (self.options.stickyChangeLogo) {
						self.changeLogo(false);
					}

					// Set Elements Style
					$('[data-sticky-header-style]').each(function() {
						var $el  = $(this),
						    css  = theme.fn.getOptions($el.data('sticky-header-style-deactive')),
						    opts = theme.fn.getOptions($el.data('sticky-header-style'));

						if ($window.width() > opts.minResolution) {
							$el.css(css);
						}
					});

					$.event.trigger({
						type: 'stickyHeader.deactivate'
					});
				};

				// Always Sticky
				if (parseInt(self.options.stickyStartAt) <= 0) {
					self.activateStickyHeader();
				}

				// Notice Top Bar
				if ($('.notice-top-bar').get(0)) {
					self.options.stickyStartAt = $('.notice-top-bar').outerHeight();
				}

				// Set Logo
				if (self.options.stickyChangeLogo) {

					var $logoWrapper    = self.options.wrapper.find('.header-logo'),
					    $logo           = $logoWrapper.find('img'),
					    logoWidth       = $logo.attr('width'),
					    logoHeight      = $logo.attr('height'),
					    logoSmallTop    = parseInt($logo.attr('data-sticky-top') ? $logo.attr('data-sticky-top') : 0),
					    logoSmallWidth  = parseInt($logo.attr('data-sticky-width') ? $logo.attr('data-sticky-width') : 'auto'),
					    logoSmallHeight = parseInt($logo.attr('data-sticky-height') ? $logo.attr('data-sticky-height') : 'auto');

					if (self.options.stickyChangeLogoWrapper) {
						$logoWrapper.css({
							'width':  $logo.outerWidth(true),
							'height': $logo.outerHeight(true)
						});
					}

					self.changeLogo = function(activate) {
						if (activate) {

							$logo.css({
								'top':    logoSmallTop,
								'width':  logoSmallWidth,
								'height': logoSmallHeight
							});

						} else {

							$logo.css({
								'top':    0,
								'width':  logoWidth,
								'height': logoHeight
							});

						}
					};

					$.event.trigger({
						type: 'stickyChangeLogo.loaded'
					});

				}

				// Side Header
				var headerBodyHeight,
				    flag = false;

				self.checkSideHeader = function() {
					if ($window.width() < 992 && flag == false) {
						headerBodyHeight = self.options.headerBody.height();
						flag = true;
					}

					if (self.options.stickyStartAt == 0 && sideHeader) {
						self.options.wrapper.css('min-height', 0);
					}

					if (self.options.stickyStartAt > 0 && sideHeader && $window.width() < 992) {
						self.options.wrapper.css('min-height', headerBodyHeight);
					}
				};

				return this;
			},

			events: function() {
				var self = this;

				if (!this.options.stickyEnableOnBoxed && $('body').hasClass('boxed') || $('html').hasClass('side-header-hamburguer-sidebar') || !this.options.stickyEnabled) {
					return this;
				}

				if (!self.options.alwaysStickyEnabled) {
					$(window).on('scroll resize', function() {
						self.checkStickyHeader();
					});
				} else {
					self.activateStickyHeader();
				}

				$(window).on('load resize', function() {
					self.checkSideHeader();
				});

				return this;
			}

		}

	});

}).apply(this, [window.theme, jQuery]);